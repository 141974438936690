<template>
  <div class="ad-approval-settings">
    <transition name="fade" mode="out-in">
      <div v-if="campaignId" class="ad-approval-settings__container">
        <div class="ad-approval-settings__container--top-row">
          <ad-approval-response-time v-model="creativeSettings.response_time" :loading="loading" />
          <ad-approval-banned-words v-model="creativeSettings.banned_words" />
        </div>
        <ad-approval-dos-and-donts v-model="creativeSettings.guidelines" />
      </div>

      <ad-approval-empty-state v-else />
    </transition>
  </div>
</template>

<script>
import AdApprovalResponseTime from '@/components/adApprovals/settings/AdApprovalResponseTime';
import AdApprovalDosAndDonts from '@/components/adApprovals/settings/AdApprovalDosAndDonts';
import AdApprovalBannedWords from '@/components/adApprovals/settings/AdApprovalBannedWords';
import AdApprovalEmptyState from '@/components/adApprovals/AdApprovalEmptyState';
import AdApprovalMixin from '@/mixins/AdApprovalMixin';

export default {
  name: 'AdApprovalSettings',
  components: { AdApprovalResponseTime, AdApprovalDosAndDonts, AdApprovalBannedWords, AdApprovalEmptyState },
  mixins: [AdApprovalMixin],
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    creativeSettings: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss">
.ad-approval-settings__container {
  margin-top: $--clb-space-1;
  display: flex;
  flex-direction: column;

  &--top-row {
    display: flex;
    flex-direction: column;

    @include sm-up {
      flex-direction: row;
    }
  }
}
</style>
