<template>
  <div class="ad-approval-new-request">
    <div class="ad-approval-new-request__inner">
      <app-heading class="ad-approval-new-request__inner-headline" level="h5">
        New Creative Request
      </app-heading>
      <app-text>Complete at least one content type to be able to submit an approval request</app-text>
      <el-form ref="form" :model="data">
        <div class="ad-approval-new-request__inner-main">
          <div v-for="(contentType, i) in textContentTypes" :key="`content${i}`" class="ad-approval-new-request__inner-main-content">
            <div class="ad-approval-new-request__inner-main-content__heading">
              <app-text weight="bold" color="grey-darker" size="h6">
                {{ contentType.type | capitalize }}
              </app-text>
              <app-button
                state="text"
                class="add-btn"
                :class="{[`add-btn-${contentType.type}`]: contentType}"
                text-color="grey-dark"
                @click="addNewRow(contentType.name)">
                <i class="el-icon-circle-plus" />
              </app-button>
            </div>
            <div
              v-for="(item, index) in data[contentType.name]"
              :key="`${item.type}-${index}`"
              class="ad-approval-new-request__inner-main-input">
              <el-form-item
                :rules="rules.bannedWordCheck"
                :show-message="false"
                :prop="`${contentType.name}.${index}.content`">
                <div class="ad-approval-new-request__inner-main-row">
                  <el-input
                    :ref="`${item.type}${index}`"
                    v-model="item.content"
                    size="mini"
                    :autosize="{ minRows: item.type === 'body' ? 4 : 2}"
                    type="textarea"
                    @input="onInputChange" />
                  <transition name="el-fade-in" mode="out-in">
                    <app-button
                      v-if="index > 0 || item.content.length > 0"
                      weight="semi-bold"
                      class="delete-btn"
                      :class="{[`delete-btn-${contentType.type}`]: contentType}"
                      state="text"
                      @click="deleteRow(contentType.name, index)">
                      <i class="el-icon-delete" />
                    </app-button>
                  </transition>
                </div>
              </el-form-item>
              <div class="add-note-section">
                <div v-tippy="{ enabled: true, showOnInit: false, content: tooltipContent, arrow: true, followCursor: false }" class="add-note-section__note-icon">
                  <i class="el-icon-document" />
                </div>
                <transition name="fade" mode="out-in">
                  <div v-if="!item.hasNote" key="addnotebtn">
                    <app-button
                      class="add-note-btn"
                      size="mini"
                      text-color="primary"
                      state="text"
                      @click="handleAddNote(index, contentType.name, true)">
                      Add Note for Advertiser
                    </app-button>
                  </div>
                  <div v-else key="addnoteinput" class="add-note-section__edit">
                    <div class="add-note-section__edit__input">
                      <el-input
                        v-model="item.notes"
                        placeholder="Context for the request (ex. channel, geo...)"
                        size="mini" />
                    </div>
                    <div>
                      <app-button
                        weight="semi-bold"
                        state="text"
                        class="delete-note-btn"
                        @click="handleAddNote(index, contentType.name, false)">
                        <i class="el-icon-delete" />
                      </app-button>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>

          <div class="ad-approval-new-request__inner-main-visual">
            <app-heading color="grey-darker" level="h6">
              Visual Content
            </app-heading>
            <ad-approval-visual-content
              :images="data.images"
              :meta="imageMeta"
              @update-image="updateImage"
              @set-images="setImages"
              @delete-image="deleteImage" />
          </div>
          <div class="ad-approval-new-request__inner-footer">
            <app-button class="cancel-ad-approval-new-request-view" state="secondary" size="mini" @click="hide">
              Cancel
            </app-button>
            <app-button :loading="saveBtnLoading" :disabled="submitIsDisabled" size="mini" @click="submitForm">
              Submit
            </app-button>
          </div>
          <transition v-if="feedbackMessage" name="fade" tag="div" mode="out-in">
            <app-text
              key="feedback"
              type="div"
              :class="`ad-approval-new-request__${feedbackMessage.includesBannedWords.class}`">
              <div><i key="icon" class="el-icon-remove-outline" /> {{ feedbackMessage.includesBannedWords.message }}</div>
            </app-text>
          </transition>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import AdApprovalVisualContent from '@/components/adApprovals/AdApprovalVisualContent';
import AdApprovalMixin from '@/mixins/AdApprovalMixin';

export default {
  name: 'AdApprovalNewRequest',
  components: { AdApprovalVisualContent },
  mixins: [AdApprovalMixin],
  data() {
    const checkBannedWordsExist = (rule, value, callback) => {
      if (this.findBannedWords(value.toLowerCase()).length) {
        callback(new Error(`Cannot use banned words: ${this.usedBannedWords.join(', ')}. Please remove to continue submission`));
      } else {
        callback();
      }
    };

    return {
      saveBtnLoading: false,
      tooltipContent: 'Give the Advertiser more context to approve your request by providing information about where and how it will be run',
      textContentTypes: [
        { name: 'headlines', type: 'headline' },
        { name: 'bodies', type: 'body' }
      ],
      data: {
        headlines: [{ content: '', type: 'headline', notes: '', hasNote: false }],
        bodies: [{ content: '', type: 'body', notes: '', hasNote: false }],
        images: []
      },
      rules: {
        bannedWordCheck: [
          {
            validator: checkBannedWordsExist,
            trigger: ['change', 'blur']
          }
        ]
      }
    };
  },
  computed: {
    feedbackMessage() {
      if (this.usedBannedWords.length) {
        return {
          includesBannedWords: {
            class: 'error',
            message: `Cannot use banned words: ${this.usedBannedWords.join(', ')}. Please remove to continue submission`
          }
        };
      }
      return false;
    },
    allText() {
      return [...this.data.headlines, ...this.data.bodies].reduce((a, { content }) => a + ' , ' + content.toLowerCase(), '');
    },
    bannedWords() {
      return this.$store.getters.adBannedWords;
    },
    hasHeadlines() {
      return this.data.headlines.some((headline) => headline.content.length > 0);
    },
    hasBodies() {
      return this.data.bodies.some((body) => body.content.length > 0);
    },
    hasImages() {
      return this.data.images.length > 0;
    },
    submitIsDisabled() {
      return (!this.hasHeadlines && !this.hasBodies && !this.hasImages) || !!this.usedBannedWords.length || this.saveBtnLoading;
    },
    usedBannedWords() {
      return this.findBannedWords(this.allText) || [];
    },
    imageMeta() {
      return { orgId: this.orgId, campaignId: this.campaignId };
    }
  },
  destroyed() {
    this.$store.dispatch('setShouldPreventNavigation', false);
  },
  methods: {
    onInputChange() {
      this.$store.dispatch('setShouldPreventNavigation', this.hasHeadlines || this.hasBodies);
    },
    addNewRow(type) {
      const contentType = type === 'headlines' ? 'headline' : 'body';
      if (this.data[type]) {
        const i = this.data[type].push({ content: '', type: contentType, notes: '' });
        this.$nextTick(() => {
          this.$refs[`${contentType}${i - 1}`][0].focus();
        });
      }
    },
    deleteRow(type, index) {
      index > 0
        ? this.data[type].splice(index, 1)
        : this.data[type][index].content = '';
    },
    setImages(images) {
      this.$set(this.data, 'images', images);
    },
    deleteImage(index) {
      this.data.images.splice(index, 1);
    },
    updateImage({ index, image }) {
      this.$set(this.data.images, index, image);
    },
    handleAddNote(index, type, mode) {
      const newData = this.data[type][index];
      newData.hasNote = mode;
      if (!mode) newData.notes = '';
      this.$set(this.data[type], index, newData);
    },
    findBannedWords(value) {
      return Object.keys(this.bannedWords).filter((word) => {
        // Regexp to match words and phrases, even ones that contain special characters
        const regex = new RegExp(`(^|\\s|\\b)${word.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&').replaceAll(/\s+/g, '\\s+')}(\\s|\\b|$)`, 'gi');
        return value && value.match(regex) && value.match(regex).length;
      });
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveNewRequest();
        } else {
          // scroll to the first error view
          this.$nextTick(() => {
            const errors = this.$el.querySelectorAll('.el-form-item.is-error');
            if (errors.length > 0) {
              window.scroll({
                top: errors[0].offsetTop - 60,
                left: 0,
                behavior: 'smooth'
              });
            }
          });
        }
      });
    },
    async saveNewRequest() {
      try {
        this.saveBtnLoading = true;
        const headlines = this.data.headlines.filter((creative) => !!creative.content.trim());
        const bodies = this.data.bodies.filter((creative) => !!creative.content.trim());
        const images = [...this.data.images].map((image) => {
          delete image.src;
          return image;
        });
        const params = [...headlines, ...bodies, ...images];
        await this.$api.adApprovals.setNewContentRequest({
          campaignId: this.campaignId,
          params,
          storeAction: 'newContentRequest'
        });
        this.hide();
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        if (this.$api.hasErrors(error)) {
          error.response.data._meta.errors.forEach((message, index) => {
            // allow enough time for previous message to animate in
            setTimeout(() => this.$message({ message, type: 'error' }), index * 300);
          });
          return;
        }
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.saveBtnLoading = false;
      }
    },
    hide() {
      this.$emit('cancel');
    }
  }

};
</script>

<style lang="scss">
.ad-approval-new-request {
  textarea {
    vertical-align: top;
  }

  background: $--clb-color-grey__white-ter;
  display: flex;
  border: $--clb-border-complete-light;
  border-radius: $--clb-border-radius;

  &__inner {
    width: 100%;
    padding: $--clb-layout-1;

    &-main {
      margin-top: $--clb-space-3;

      &-input {
        margin-bottom: $--clb-space-4;

        .delete-note-btn,
        .delete-btn {
          padding: 0;
          min-width: 40px;
          height: 33px;
          cursor: pointer;
        }
      }

      &-content {
        &__heading {
          display: flex;

          .add-btn {
            padding: 0 $--clb-space-2;
            height: $--clb-space-6;
          }

          &-btn {
            padding: 0;
          }
        }
      }

      &-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .el-textarea {
          max-width: calc(100% - 40px);
        }
      }

      &-body,
      &-visual,
      &-headline {
        margin-bottom: $--clb-space-4;
      }
    }

    &-footer {
      display: flex;
      justify-content: flex-end;

      .cancel-ad-approval-new-request-view {
        margin-right: $--clb-space-2;
      }
    }
  }

  &__error {
    background: #f8e6e6;
    margin-top: $--clb-space-3;
    padding: $--clb-space-2;
    color: $--clb-body-font;
    border-radius: $--clb-border-radius;

    .el-icon-remove-outline {
      color: $--clb-color-red;
      font-weight: $--clb-font-weight__semi-bold;
    }
  }

  .add-note {
    &-section {
      display: flex;
      align-items: center;
      min-height: 40px;
      padding-top: $--clb-space-1;

      &__note-icon {
        padding: 0 $--clb-space-2;
      }

      &__edit {
        display: flex;
        align-items: center;
        width: 100%;

        &__input {
          flex-grow: 1;
        }
      }
    }

    &-btn {
      padding: 0;
      height: $--clb-space-6;
    }
  }
}
</style>
