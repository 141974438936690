<template>
  <div class="app-preview-card" :class="{['app-preview-card--' + size]: size}" :style="{ width:widthComputed + 'px'}">
    <app-heading v-if="title" type="h3">
      {{ title }}
    </app-heading>

    <div v-if="overlay" class="app-preview-card__overlay" :style="{ height:heightComputed + 'px', width:widthComputed + 'px'}">
      <div class="app-preview-card__overlay-screen" />
      <span class="app-preview-card__overlay-name">
        {{ overlay }}
      </span>
      <span v-if="overlayNumber" class="app-preview-card__overlay-number">{{ overlayNumber }}</span>
    </div>

    <div
      class="app-preview-card__background"
      :class="{['app-preview-card__background-color-'+backgroundColor]: backgroundColor}"
      :style="{ height:heightComputed + 'px', width:widthComputed + 'px'}">
      <slot name="background">
        <div
          class="app-preview-card__background-img"
          :style="{backgroundImage: `url(${backgroundImage})`, height:heightComputed + 'px', width:widthComputed + 'px'}" />
        <i v-if="!backgroundImage" class="el-icon-warning-outline app-preview-card__warning-icon" />
      </slot>
    </div>
  </div>
</template>

<script>
import AppHeading from '@/components/AppHeading';
import { allowedColors } from '@/components/AppText';

const sizes = [
  {
    name: 'small',
    width: 72,
    height: 80
  },
  {
    name: 'medium',
    width: 144,
    height: 160
  },
  {
    name: 'large',
    width: 165,
    height: 195
  }
];
export default {
  name: 'AppPreviewCard',
  components: { AppHeading },
  props: {
    width: {
      type: String,
      default: undefined
    },
    height: {
      type: String,
      default: undefined
    },
    size: {
      type: String,
      default: 'small',
      validator: prop => sizes.map(size => size.name).includes(prop)
    },
    backgroundImage: {
      type: String,
      default: undefined
    },
    backgroundColor: {
      type: String,
      default: 'grey-light',
      validator: function(value) {
        // The color must match one of these values
        return allowedColors.indexOf(value) !== -1;
      }
    },
    title: {
      type: String,
      default: undefined
    },
    overlay: {
      type: String,
      default: undefined
    },
    overlayNumber: {
      type: Number,
      default: undefined
    }
  },
  computed: {
    widthComputed() {
      if (this.width) return this.width;
      if (this.size) {
        const foundSize = sizes.find(size => size.name === this.size);
        if (foundSize) return foundSize.width;
      }
      return null;
    },
    heightComputed() {
      if (this.height) return this.height;
      if (this.size) {
        const foundSize = sizes.find(size => size.name === this.size);
        if (foundSize) return foundSize.height;
      }
      return null;
    }
  },
  methods: {
    formatSizeValue(value) {
      return isFinite(value) ? `${value}px` : `${value}`;
    }
  }

};
</script>

<style lang="scss">
@import '../theme/clb/common/clb-var.scss';

.app-preview-card {
  transition: transform $--clb-hover-transition;
  position: relative;

  &__background-color {
    &-default {
      background-color: $--clb-body-font;
    }

    &-primary {
      background-color: $--clb-color-primary;
    }

    &-primary-light {
      background-color: $--clb-color-primary__light;
    }

    &-primary-dark {
      background-color: $--clb-color-primary__dark;
    }

    &-black {
      background-color: $--clb-color__headings;
    }

    &-white {
      background-color: $--clb-color-primary__white;
    }

    &-white-ter {
      background-color: $--clb-color-grey__white-ter;
    }

    &-grey-lighter {
      background-color: $--clb-color-grey__grey-lighter;
    }

    &-grey-light {
      background-color: $--clb-color-grey__grey-light;
    }

    &-grey {
      background-color: $--clb-color-grey__grey;
    }

    &-grey-dark {
      background-color: $--clb-color-grey__grey-dark;
    }

    &-grey-darker {
      background-color: $--clb-color-grey__grey-darker;
    }

    &-success {
      background-color: $--clb-color-success;
    }

    &-warning {
      background-color: $--clb-color-warning;
    }

    &-danger {
      background-color: $--clb-color-danger;
    }
  }

  &__background {
    position: relative;
    border-radius: $--clb-border-radius;
    height: 200px;
    flex: 0 0 auto;
    overflow: hidden;
    transition: transform $--clb-hover-transition, box-shadow $--clb-hover-transition, border $--clb-hover-transition;
    border: 1px solid $--clb-border-color-base;

    &-img {
      position: absolute;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      transition: transform $--clb-hover-transition;
      transform: scale(1.01);
    }
  }

  &__overlay {
    position: absolute;
    transition: transform $--clb-hover-transition;
    bottom: 0;
    z-index: 2;

    &-name {
      font-size: $--clb-font-size-sm;
      font-weight: $--clb-font-weight__bold;
      color: $--clb-color-primary__white;
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-shadow: 0 0 2px #212121;
      position: absolute;
      z-index: 2;
      bottom: 8px;
      text-align: right;
      width: 116px;
    }

    &-screen {
      bottom: 0;
      height: 50%;
      width: 100%;
      z-index: 1;
      position: absolute;
      border-radius: 4px;
      background:
        linear-gradient(
          to top,
          rgba(33, 33, 33, 0.4),
          cubic-bezier(0.445, 0.05, 0.55, 0.95),
          rgba(33, 33, 33, 0)
        );
    }

    &-number {
      margin: 60px 0 0 $--clb-layout-2;
      font-size: 168px;
      color: $--clb-color-primary;
      font-weight: $--clb-font-weight__bold;
      opacity: 0.7;
      position: absolute;
      z-index: 1;
      width: 120px;
      text-align: right;
    }
  }

  &__warning-icon {
    width: 100%;
    text-align: center;
  }

  &:hover:not(:disabled) {
    .app-preview-card__background {
      border: 1px solid $--clb-color-primary;
      box-shadow: 0 2px 8px 0 $--clb-shadow-color;
      cursor: pointer;
      transform: scale(1.08);
      transform: translateY(-4px) !important;

      &-img {
        transform: scale(1.08);
      }
    }

    .app-preview-card__overlay {
      transform: translateY(-4px) !important;
    }
  }
}
</style>
