import _get from 'lodash/get';
import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import AppButton from '@/components/AppButton';
import AppText from '@/components/AppText';
import AppHeading from '@/components/AppHeading';
import Breakpoints from '@/mixins/Breakpoints';
import dayjs from 'dayjs';

export default {
  components: { AppButton, AppText, AppHeading },
  mixins: [Breakpoints([450, 768, 992])],
  computed: {
    orgId() {
      return this.$store.getters.organizationId;
    },
    orgType() {
      return this.$store.getters.organization.type;
    },
    isAffiliate() {
      return this.$store.getters.userIsAffiliate;
    },
    isAdvertiser() {
      return this.$store.getters.userIsAdvertiser;
    },
    isInternal() {
      return this.$store.getters.userIsInternal;
    },
    activeTab() {
      return this.$route.params.tab;
    },
    query() {
      return this.$route.query;
    },
    campaignId() {
      return this.$route.params.id;
    },
    campaign() {
      return this.$store.getters.campaign(this.campaignId);
    },
    affiliateId() {
      return _get(this.$route, 'query.affiliateId');
    },
    contentType() {
      return _get(this.$route, 'query.contentType');
    },
    actionBy() {
      return _get(this.$route, 'query.actionBy');
    },
    isActiveTabSettings() {
      return this.$route.params.tab === 'settings';
    },
    dateFilter() {
      return _omitBy({
        dateRange: _get(this.$route, 'query.dateRange', this.defaultDateRange.dateRange),
        startDate: _get(this.$route, 'query.startDate', this.defaultDateRange.startDate),
        endDate: _get(this.$route, 'query.endDate', this.defaultDateRange.endDate)
      }, _isNil);
    },
    defaultDateRange() {
      return { dateRange: 'CUSTOM', startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'), endDate: dayjs().format('YYYY-MM-DD') };
    },
    colWidth() {
      if (this.isAffiliate) return '120px';
      return this.xsUp ? '6rem' : '5rem';
    },
    xsOnly() {
      return this.windowBreakpoint === 0;
    },
    xsDown() {
      return this.windowBreakpoint <= 450;
    },
    xsUp() {
      return this.windowBreakpoint >= 450;
    },
    smUp() {
      return this.windowBreakpoint >= 768;
    },
    mdDown() {
      return this.windowBreakpoint < 992;
    }
  }
};
