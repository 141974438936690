<template>
  <div class="ad-approval-dos-and-donts">
    <app-heading class="ad-approval-dos-and-donts__heading" level="h2" size="h4" weight="semi-bold">
      Dos & Donts
      <app-button
        v-if="!isAffiliate"
        state="text"
        class="ad-approval-dos-and-donts__edit-btn"
        @click="toggleEditMode()">
        <i :class="[editMode? 'active':'']" class="el-icon-edit ad-approval-dos-and-donts__edit-icon" />
      </app-button>
    </app-heading>
    <div class="ad-approval-dos-and-donts__sub-heading">
      <app-text>
        These are the rules of conduct for creatives in the campaign.
      </app-text>
    </div>
    <transition name="fade" mode="out-in">
      <div v-if="editMode" key="edit-section" class="ad-approval-dos-and-donts__edit">
        <vue-editor v-model="guidelines" />
        <app-button :loading="isBtnLoading" :disabled="isBtnLoading" state="primary" class="ad-approval-dos-and-donts__save-btn" @click="toggleEditMode(false)">
          Save
        </app-button>
      </div>
      <div v-else key="view-section" class="ad-approval-dos-and-donts__view ql-snow">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="ql-editor" v-html="(value)" />
      </div>
    </transition>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import AdApprovalMixin from '@/mixins/AdApprovalMixin';

export default {
  name: 'AdApprovalDosAndDonts',
  components: { VueEditor },
  mixins: [AdApprovalMixin],
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      editMode: false,
      isBtnLoading: false
    };
  },
  computed: {
    isAffiliate() {
      return this.$store.getters.userIsAffiliate;
    },
    guidelines: {
      get() { return this.value; },
      set(guidelines) {
        this.$emit('input', guidelines);
      }
    }
  },
  methods: {
    async toggleEditMode(mode = !this.editMode) {
      // save guidelines on closing the editor
      if (mode === false) await this.saveGuidelines();
      this.editMode = mode;
      this.$store.dispatch('setShouldPreventNavigation', this.editMode);
    },
    async saveGuidelines() {
      try {
        this.isBtnLoading = true;
        const options = {
          campaignId: this.campaignId,
          params: { guidelines: this.guidelines }
        };
        await this.$api.adApprovals.setGuidelines({ ...options });
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        if (this.$api.hasErrors(error)) {
          error.response.data._meta.errors.forEach((message, index) => {
            // allow enough time for previous message to animate in
            setTimeout(() => this.$message({ message, type: 'error' }), index * 300);
          });
          return;
        }
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.isBtnLoading = false;
      }
    }
  }
};
</script>
<style lang="scss">
@import '~quill/dist/quill.snow.css';

.ad-approval-dos-and-donts {
  padding: $--clb-space-4;
  border-radius: $--clb-border-radius;
  border: $--clb-border-complete-light;

  &__heading {
    margin-bottom: $--clb-space-2 !important;
  }

  &__sub-heading {
    margin-bottom: $--clb-space-1;
  }

  &__edit {
    &-btn {
      display: inline;
      padding: $--clb-space-1;
    }

    &-icon {
      color: $--clb-body-font;
      transition: all 0.3s;

      &.active {
        color: $--clb-color-grey__grey-light;
      }
    }

    .quillWrapper {
      margin-bottom: $--clb-space-3;
    }
  }

  &__view {
    .ql-editor {
      padding: $--clb-space-4;
      background-color: $--clb-color-grey__white-est;
      border-radius: $--clb-border-radius;
    }
  }

  &__save {
    margin-top: $--clb-space-3;
  }
}
</style>
