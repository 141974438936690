<template>
  <div class="ad-approval-filters">
    <portal v-if="isActiveTabSettings" to="campaign-page-navigation">
      <div class="tw-h-layout-2 tw-mb-space-4">
        <app-link
          underline="hover-on"
          weight="medium"
          class="!tw-text-jb-indigo tw-block tw-text-sm tw-mt-space-2"
          @click="navClick('pending')">
          <chevron-previous-icon class="!tw-text-jb-indigo tw-w-space-2 tw-mr-space-2 tw-h-space-3" />
          Back to Approvals
        </app-link>
      </div>
    </portal>
    <portal
      v-if="!isActiveTabSettings"
      to="campaign-page-header-slot">
      <app-link
        key="view_guidelines"
        underline="hover-on"
        weight="medium"
        class="!tw-text-jb-indigo tw-mt-space-2 tw-mb-space-4 tw-block tw-text-sm"
        @click="navClick('settings')">
        View Guidelines
      </app-link>
      <div class="tw-flex tw-flex-wrap tw-gap-8">
        <div>
          <app-heading level="h5" weight="medium" class="tw-text-jb-grey-700 tw-mb-space-4px tw-text-xs">
            Timeline
          </app-heading>
          <date-picker-filter v-model="filterDateValue" class="tw-w-layout-6" />
        </div>
        <transition name="fade" mode="out-in">
          <div v-if="affiliates.length">
            <app-heading level="h5" weight="medium" class="tw-text-jb-grey-700 tw-mb-space-4px tw-text-xs">
              Affiliate
            </app-heading>
            <dropdown-filter
              v-model="filterAffiliateId"
              :clearable="true"
              :remote="false"
              option-style="nameOnly"
              :append-to-body="true"
              :loading="isLoadingLists"
              :data="affiliates" />
          </div>
        </transition>
      </div>
    </portal>
    <portal to="ad-approval-inner-filters">
      <div class="ad-approval-inner-filters tw-bg-jb-indigo-lighter tw-p-space-4 tw-block">
        <div class="ad-approval-inner-filters__header tw-flex">
          <transition name="fade" mode="out-in">
            <div v-if="activeTab === 'pending'" key="content-type-filter">
              <app-heading level="h5" weight="medium" class="tw-text-jb-grey-700 tw-mb-space-4px tw-text-xs">
                Content Type
              </app-heading>
              <dropdown-filter
                v-model="contentTypeFilter"
                :clearable="true"
                :remote="false"
                :append-to-body="true"
                option-style="nameOnly"
                :data="contentTypeFilters"
                @input="handleApplyFilters" />
            </div>
            <div v-else-if="activeTab === 'revoked'" key="action-by-filter">
              <app-heading level="h5" weight="medium" class="tw-text-jb-grey-700 tw-mb-space-4px tw-text-xs">
                Action By
              </app-heading>
              <dropdown-filter
                v-model="actionByFilter"
                :clearable="true"
                :remote="false"
                option-style="nameOnly"
                :data="actionByFilters"
                :append-to-body="true"
                @input="handleApplyFilters" />
            </div>
          </transition>
        </div>
      </div>
    </portal>
  </div>
</template>
<script>

import _get from 'lodash/get';
import _pickBy from 'lodash/pickBy';
import _identity from 'lodash/identity';
import DatePickerFilter from '@/components/filters/DatePickerFilter';
import DropdownFilter from '@/components/filters/DropdownFilter';
import AdApprovalMixin from '@/mixins/AdApprovalMixin';
import ChevronPreviousIcon from '@/assets/svg/chevron-previous-icon.svg';
import AppLink from '@/components/AppLink';

export default {
  name: 'AdApprovalFilters',
  components: { DatePickerFilter, DropdownFilter, ChevronPreviousIcon, AppLink },
  mixins: [AdApprovalMixin],
  data() {
    return {
      isLoadingLists: false,
      filterAffiliateId: null,
      filterDateValue: { dateRange: 'CW' },
      data: [{ disabled: true }],
      searchVal: '',
      contentTypeFilter: '',
      actionByFilter: '',
      contentTypeFilters: [
        { id: 'headline', name: 'Headline' },
        { id: 'body', name: 'Body' },
        { id: 'visual', name: 'Visual' }
      ],
      actionByFilters: [
        { id: 'advertiser', name: 'Account Manager' },
        { id: 'affiliate', name: 'Brand' }
      ]
    };
  },
  computed: {
    filterQuery() {
      const query = { affiliateId: this.filterAffiliateId, ...this.filterDateValue };
      if (this.contentTypeFilter && this.activeTab === 'pending') query.contentType = this.contentTypeFilter;
      if (this.actionByFilter && this.activeTab === 'revoked') query.actionBy = this.actionByFilter;

      return _pickBy(query, _identity);
    },
    affiliates() {
      return this.$store.getters.adAffiliates;
    }
  },
  watch: {
    campaignId: {
      immediate: true,
      handler(campaignId) {
        this.fetchCampaignAffiliateList();
      }
    },
    filterAffiliateId() {
      this.handleApplyFilters();
    },
    filterDateValue() {
      this.handleApplyFilters();
    },
    activeTab() {
      this.handleApplyFilters();
    }
  },
  activated() {
    this.contentTypeFilter = '';
  },
  methods: {
    async fetchCampaignAffiliateList(searchString = '') {
      try {
        this.isLoadingLists = true;
        await this.$api.adApprovals.fetchCampaignAffiliateList({
          orgId: this.orgId,
          q: this.campaignId,
          campaignId: this.campaignId,
          params: { page: 1 },
          cancellationKey: `adApprovals-${this.orgId}-${searchString}`,
          storeAction: 'fetchCampaignAffiliateList'
        });
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.isLoadingLists = false;
      }
    },
    handleApplyFilters() {
      this.$router.replace({
        name: 'dashboard-campaign-setup-approvals',
        params: {
          tab: _get(this.$route, 'params.tab', 'pending'),
          id: this.campaignId
        },
        query: this.filterQuery
      });
    },
    navClick(tab) {
      this.$emit('nav-click', tab);
    },
    stopTheEvent($event) {
      $event.stopPropagation();
    },
    collapse() {
      this.collapsed = !this.collapsed;
    }
  }
};
</script>
