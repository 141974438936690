<template>
  <div class="ad-approval-visual-content">
    <app-file-uploader
      v-model="images"
      class="ad-approval-visual-content__uploader"
      :multiple="multiple"
      :max-count="maxCount"
      :meta="meta"
      @input="setImages" />
    <div v-for="(image, index) in images" :key="'image' + index">
      <div class="ad-approval-visual-content__main">
        <div class="tw-p-space-4 tw-flex tw-w-full tw-bg-white">
          <div v-if="image.metadata && image.metadata.type" class="tw-pr-space-4 tw-mt-5 xs:tw-mt-2.5 ad-approval-visual-content__main__images-image">
            <app-preview-card
              v-if="image.metadata.type.match('image/*')"
              :width="xsOnly ? '40' : '50'"
              :height="xsOnly ? '40' : '50'"
              :background-image="image.src"
              @click.native="handleImageClick(image.src)" />
            <div v-else>
              <video width="80" controls="controls" preload="metadata" :src="`${image.src}#t=0.001`" :type="image.metadata.type" />
            </div>
          </div>
          <div class="tw-self-center tw-w-full">
            <label><app-text color="grey-darker" weight="medium">Content Name</app-text>
              <span class="tw-ml-1">
                <i
                  v-tippy="{enabled: true,
                            showOnInit: false,
                            placement: xsOnly ? 'top-end' : 'top',
                            maxWidth: xsOnly ? 225 : 350,
                            offset: xsOnly ? '40, 0' : '0, 0',
                            arrow: true,
                            followCursor: false,
                            content: tooltipInputContent}"
                  class="el-icon-info tw-text-jb-grey-400" />
              </span>

            </label>
            <el-form-item
              class="tw-mb-0"
              :prop="'images.' + index + '.name'"
              :rules="
                [
                  {required: true, type: 'string', message: `<i class='el-icon-warning tw-mr-1'></i>Content Name Required`, trigger: 'change'},
                  { min: 1, max: 255, type: 'string', message: `<i class='el-icon-warning tw-mr-1'></i>Name cannot be longer than 255 characters`, trigger: 'change'}
                ]">
              <el-input
                :ref="`imageContent${index}`"
                v-model="image.name"
                placeholder="Searchable context for this content ex. who’s in it? Theme? Promotion? Discount code?"
                size="mini" />
              <div slot="error" slot-scope="error" class="tw-text-jb-red-dark tw-text-sm" v-html="error.error" />
            </el-form-item>
          </div>
        </div>
        <div class="tw-mt-9">
          <app-button class="ad-approval-visual-content__main__images__delete-btn" weight="semi-bold" state="text" @click="deleteImage(index)">
            <i class="el-icon-delete" />
          </app-button>
        </div>
      </div>
      <div class="add-note-section">
        <div v-tippy="{ enabled: true, showOnInit: false, content: tooltipNoteContent, arrow: true, followCursor: false }" class="add-note-section__note-icon">
          <i class="el-icon-document" />
        </div>
        <transition name="fade" mode="out-in">
          <div v-if="!image.hasNote" key="addnotebtn">
            <app-button
              class="add-note-btn"
              size="mini"
              text-color="primary"
              state="text"
              @click="handleAddNote(index, true)">
              Add Note for Advertiser
            </app-button>
          </div>
          <div v-else key="addnoteinput" class="add-note-section__edit">
            <div class="add-note-section__edit__input">
              <el-input
                v-model="image.notes"
                placeholder="Context for the request (ex. channel, geo...)"
                size="mini" />
            </div>
            <div>
              <app-button
                weight="semi-bold"
                state="text"
                class="delete-note-btn"
                @click="handleAddNote(index, false)">
                <i class="el-icon-delete" />
              </app-button>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <app-lightbox
      v-if="lightboxVisible && lightboxImage"
      :is-visible="lightboxVisible"
      :image-url="lightboxImage"
      :navigate="false"
      @close="lightboxVisible = false" />
  </div>
</template>

<script>
import AppFileUploader from '@/components/AppFileUploader';
import AppPreviewCard from '@/components/AppPreviewCard';
import AppLightbox from '@/components/AppLightbox';
import AdApprovalMixin from '@/mixins/AdApprovalMixin';

export default {
  name: 'AdApprovalVisualContent',
  components: { AppFileUploader, AppPreviewCard, AppLightbox },
  mixins: [AdApprovalMixin],
  props: {
    images: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      default: undefined,
      required: false
    },
    maxCount: {
      type: Number,
      default: 20
    },
    multiple: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      lightboxVisible: false,
      tooltipNoteContent: 'Give the Advertiser more context to approve your request by providing information about where and how it will be run',
      tooltipInputContent: `
      <div class="ad-approval-visual-content__icon"><i class="el-icon-close"></i></div>
       Consider adding contexts such as a theme or promo code to the name to enable others to find this content through search.
      `
    };
  },
  methods: {
    handleImageClick(image) {
      this.lightboxImage = image;
      this.lightboxVisible = true;
    },
    setImages() {
      this.$emit('set-images', this.formatImages(this.images));
    },
    formatImages(images) {
      return (images).map((image) => {
        image.name = image.name ? image.name : '';
        image.hasNote = image.hasNote ? image.hasNote : false;
        image.notes = image.notes ? image.notes : '';
        return image;
      });
    },
    deleteImage(index) {
      this.$emit('delete-image', index);
    },
    handleAddNote(index, mode) {
      const newData = this.images[index];
      newData.hasNote = mode;
      if (!mode) newData.notes = '';
      this.$emit('update-image', { index, image: newData });
    }
  }
};
</script>

<style lang="scss">
.ad-approval-visual-content {
  &__uploader {
    border-radius: $--clb-border-radius;

    @include xs-up {
      margin-right: $--clb-layout-3;
    }
  }

  &__main {
    margin-top: $--clb-space-4;
    display: flex;
    justify-content: space-between;

    > :not(:last-child) {
      border-bottom: $--clb-border-complete-light;
    }

    &__error {
      width: 100%;
      padding: $--clb-space-4;
    }

    &__images {
      display: flex;
      width: 100%;
      padding: $--clb-space-4;
      background: white;

      &__delete-btn {
        padding: 0;
        margin: 0 0 0 $--clb-space-4;
      }

      &-image {
        padding-right: $--clb-space-4;
      }

      &-input {
        flex-grow: 1;
      }
    }
  }

  .add-note {
    &-section {
      display: flex;
      align-items: center;
      min-height: 40px;
      padding-top: $--clb-space-1;

      &__note-icon {
        padding: 0 $--clb-space-4;
      }

      &__edit {
        display: flex;
        align-items: center;
        width: 100%;

        &__input {
          flex-grow: 1;
        }
      }

      .delete-note-btn,
      .delete-btn {
        padding: 0;
        margin-left: $--clb-space-4;
      }
    }

    &-btn {
      padding: 0;
    }
  }
}

.ad-approval-visual-content__icon {
  @apply sm:tw-hidden tw-block tw-text-right;
}

</style>
