<template>
  <div class="app-file-uploader">
    <div class="app-file-uploader__main">
      <label
        class="app-file-uploader__main__label"
        :class="{[activeState.class]: activeState.class}"
        @drop.prevent="handleFileDrop($event)"
        @dragover.prevent="handleDragOver(true)"
        @dragleave.prevent="handleDragOver(false)">
        <div class="app-file-uploader__main__label-inner">
          <i :class="activeState.icon" class="app-file-uploader__main__label-inner__icon" />
          <p class="app-file-uploader__main__label-inner__message" :class="{[activeState.class]: activeState.class}">{{ activeState.message }}</p>
          <input
            ref="fileUpload"
            type="file"
            class="app-file-input"
            :multiple="multiple"
            :disabled="activeState.disabled"
            @change="handleFileDrop($event)">
          <app-button
            v-if="activeState.btnText"
            state="text"
            :text-color="activeState.type ==='error' ? 'danger' : 'primary'"
            @click="$refs.fileUpload.click();">{{ activeState.btnText }}</app-button>
        </div>
      </label>
    </div>
    <transition v-if="activeError.message" name="fade">
      <div class="app-file-uploader__error-section">
        <i class="el-icon-error" />
        <app-text weight="normal">
          {{ activeError.message }}
        </app-text>
      </div>
    </transition>
  </div>
</template>

<script>
import AppButton from '@/components/AppButton';
import AppText from '@/components/AppText';
import qs from 'qs';

export default {
  name: 'AppFileUplader',
  components: { AppButton, AppText },
  props: {
    value: { // images
      type: Array,
      required: true
    },
    maxCount: {
      type: Number,
      default: 20
    },
    maxFileSize: {
      type: Number,
      default: 262144000 // 262144000 in Bytes = 250 MB
    },
    multiple: {
      type: Boolean,
      default: true
    },
    meta: {
      type: Object,
      default: undefined,
      required: false
    }
  },
  data() {
    return {
      isDraggedOver: false,
      activeState: {},
      activeError: {},
      queuedFilesLength: 0
    };
  },
  computed: {
    images: {
      get() {
        return this.value;
      },
      set(images) { this.$emit('input', images); }
    },
    imagesCount() {
      return this.images.length;
    },
    states() {
      return {
        defaultState: {
          type: 'state',
          class: '',
          icon: 'el-icon-upload',
          message: 'Drag and drop to upload or click to select a file',
          btnText: 'Upload'
        },
        draggedOver: {
          type: 'state',
          class: 'dragover',
          icon: 'el-icon-upload2',
          message: 'Drop the files here',
          btnText: 'Upload'
        },
        loading: {
          type: 'state',
          class: 'loading',
          icon: 'el-icon-loading',
          message: 'Uploading...',
          disabled: true
        },
        errorInvalidType: {
          type: 'error',
          class: 'error',
          icon: 'el-icon-warning',
          message: 'File type is not valid',
          btnText: 'Try another file'
        }
      };
    },
    errors() {
      return {
        errorInvalidSize: {
          type: 'error',
          class: 'error',
          icon: 'el-icon-warning',
          message: `The file is too big, try a file size less than ${this.niceBytes(this.maxFileSize)}`
        },
        errorMaxImagesCount: {
          type: 'error',
          class: 'error',
          icon: 'el-icon-warning',
          message: `You cannot upload more than ${this.maxCount} ${this.maxCount === 1 ? 'file' : 'files'}`
        }
      };
    }
  },
  watch: {
    value(val) {
      this.resetError();
    }
  },
  created() {
    this.activeState = this.states.defaultState;
  },
  methods: {
    handleDragOver(isDraggedOver = false) {
      // Ignore if in a disabled state, else set the appropriate state
      if (!this.activeState.disabled) {
        this.isDraggedOver = isDraggedOver;
        if (this.isDraggedOver) this.activeState = this.states.draggedOver;
        if (!this.isDraggedOver && this.activeState.type !== 'error') this.activeState = this.states.defaultState;
      }
    },
    resetState() {
      this.activeState = this.states.defaultState;
    },
    resetError() {
      this.activeError = {};
    },
    niceBytes(bytes) {
      const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      let l = 0;
      let n = parseInt(bytes, 10) || 0;

      while (n >= 1024 && ++l) {
        n = n / 1024;
      }
      // include a decimal point and a tenths-place digit if presenting
      // less than ten of KB or greater units
      return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
    },
    isValidFileType(type) {
      const validTypes = [
        'image/jpeg', // .jpg, .jpeg, .jfif, .pjpeg, .pjp
        'image/webp',
        'image/png',
        'image/gif',
        'video/mp4', // .mp4 .m4v .m4p
        'video/mpeg', // .mpeg .mpg
        'video/webm',
        'video/quicktime' // .mov
      ];
      return type.match(validTypes.join('|'));
    },
    isValidFileSize(size) {
      return size <= this.maxFileSize;
    },
    isValidMaxFileCount(existingFilesCount = 0, newFilesCount = 0) {
      return existingFilesCount + newFilesCount <= this.maxCount;
    },
    async handleFileDrop(e) {
      // Ignore if in a disabled state, else reset state before beginning.
      if (this.activeState.disabled) {
        return;
      }
      this.resetState();
      this.resetError();

      // Check if the files are coming from a drag/drop action or file uploader click
      const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
      this.queuedFilesLength += files.length;
      if (!this.isValidMaxFileCount(this.images.length, this.queuedFilesLength)) {
        this.activeError = this.errors.errorMaxImagesCount;
        this.queuedFilesLength -= files.length;
        return false;
      }
      this.activeState = this.states.loading;
      try {
        await this.handleFileUpload(files);
        this.resetState();
      } catch (error) {
        this.queuedFilesLength = 0;
      } finally {
        this.handleDragOver(false);
      }
    },
    handleFileUpload(files) {
      return Promise.all([...files].map(this.handleSingleFileUpload));
    },
    async handleSingleFileUpload(file) {
      if (!this.isValidFileType(file.type)) {
        this.activeState = this.states.errorInvalidType;
        return Promise.reject(new Error('File type not valid'));
      }

      if (!this.isValidFileSize(file.size)) {
        this.activeError = this.errors.errorInvalidSize;
        return Promise.reject(new Error('File size not valid'));
      }

      const data = { name: '' };
      const regexp = /(?:\.([^.]+))?$/;
      const extension = regexp.exec(file.name)[1] || '';
      const cloudflareResponse = await this.sendToCloudflare(file, extension);

      data.content = cloudflareResponse.url;
      data.metadata = {
        filename: file.name,
        url: cloudflareResponse.url,
        etag: cloudflareResponse.etag,
        hmac: cloudflareResponse.hash,
        size: file.size,
        extension: extension,
        type: file.type
      };
      data.type = 'visual';
      data.src = URL.createObjectURL(file);
      this.images.push(data);
      this.queuedFilesLength -= 1;
      return data;
    },
    async sendToCloudflare(file, extension) {
      try {
        let cloudflareUrl = process.env.VUE_APP_CLOUDFLARE_FILE_UPLOAD_URL;
        if (this.meta && typeof this.meta === 'object') cloudflareUrl = `${cloudflareUrl}?${qs.stringify({ ...this.meta, extension: extension })}`;

        const res = await this.$api.request({
          url: cloudflareUrl,
          data: file,
          method: 'post',
          headers: {
            'api-token': this.$store.getters.user.session_id,
            'mime-type': file.type
          }
        });

        if (!res || res.status !== 200 || !res.data) throw new Error(res && res.statusText ? res.statusText : 'There was an error uploading this image. Please try again.');
        return res.data;
      } catch (error) {
        this.$message({ message: error.message, type: 'error' });
      }
    }
  }
};
</script>

<style lang="scss">
.app-file-uploader {
  &__main {
    background: white;
    border-radius: $--clb-border-radius;
    border: 2px dashed $--clb-border-color-lighter;

    &__label {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: $--clb-layout-4 $--clb-space-6;
      border-radius: $--clb-border-radius;

      &-inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        pointer-events: none;

        &__icon {
          font-size: $--clb-layout-5;
          opacity: 0.15;
        }

        &__message {
          max-width: 200px;
          margin-top: $--clb-space-2;
          text-align: center;

          &.error {
            color: $--clb-color-error-text;
          }

          &.dragover {
            color: $--clb-color-primary;
          }
        }
      }

      &.dragover {
        border: 2px dashed $--clb-color-primary;
        background-color: $--clb-color-grey__white-ter;

        .app-file-uploader__label-inner__icon {
          opacity: 1;
          font-size: $--clb-layout-3;
          color: $--clb-color-primary;
        }
      }

      &.loading {
        cursor: not-allowed;
      }

      &.error {
        border: 2px dashed $--clb-color-error-text;
        background-color: $--clb-color-error-background;

        .app-file-uploader__label-inner__icon {
          font-size: 30px;
          opacity: 1;
          color: $--clb-color-error-text;
        }
      }

      .app-file-input {
        display: none;
      }
    }
  }

  &__error-section {
    margin: $--clb-space-4 0;
    padding: $--clb-space-4;
    background-color: $--clb-color-error-background;

    .el-icon-error {
      color: $--clb-color-error-text;
    }
  }
}
</style>
