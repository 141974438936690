<template>
  <div class="ad-approval-row__affiliate-actions">
    <template v-if="affiliateIsEditing">
      <div class="ad-approval-row__affiliate-actions-submit">
        <app-button class="ad-approval-row__affiliate-actions-submit-cancel-btn" state="secondary" size="micro" @click="handleCancel">
          Cancel
        </app-button>
        <app-button
          :disabled="submitIsDisabled"
          class="ad-approval-row__affiliate-actions-submit-btn"
          :loading="submitLoading"
          state="primary"
          size="micro"
          @click="editContent">
          Submit
        </app-button>
      </div>
    </template>
    <template v-else>
      <div v-if="contentRequestStatus === 'approved'" class="ad-approval-row__affiliate-actions-copy">
        <app-button :tippy="isVisualContent ? 'Download' : 'Copy'" class="ad-approval-row__affiliate-actions-copy-btn" state="text" @click="isVisualContent? downloadMedia() : copyToClipboard()">
          <i :class="{['el-icon-document-copy']: !isVisualContent, ['el-icon-download']: isVisualContent }" />
        </app-button>
      </div>
      <div v-else-if="showEditIcon" class="ad-approval-row__affiliate-actions-edit">
        <app-button tippy="Edit" class="ad-approval-row__affiliate-actions-edit-btn" state="text" @click="editText(item)">
          <i class="el-icon-edit" />
        </app-button>
      </div>
    </template>
  </div>
</template>

<script>
import AppButton from '@/components/AppButton';
import CopyToClipboardService from '@/services/copy-to-clipboard-service';
import DownloadMediaService from '@/services/download-media';
import AdApprovalMixin from '@/mixins/AdApprovalMixin';

export default {
  name: 'AffiliateActions',
  components: { AppButton },
  mixins: [AdApprovalMixin],
  props: {
    item: {
      type: Object,
      required: true
    },
    affiliateIsEditing: {
      type: Boolean,
      required: true
    },
    editedItem: {
      type: Object,
      required: true
    },
    visualContent: {
      type: Array,
      required: true
    },
    submitLoading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isVisualContent() {
      return this.item.content.type === 'visual';
    },
    contentRequestStatus() {
      return this.item.status;
    },
    submitIsDisabled() {
      if (this.isVisualContent) return !this.visualContent.length;
      return !this.editedItem.content || this.editedItem.content === this.item.content.content;
    },
    showEditIcon() {
      return this.contentRequestStatus !== 'approved' && this.contentRequestStatus !== 'revoked';
    }
  },
  methods: {
    handleCancel() {
      this.$emit('changeEditState', false);
    },
    editText(item) {
      this.$emit('changeEditState', true);
    },
    async editContent() {
      this.$emit('put-content', 'request');
    },
    downloadMedia() {
      DownloadMediaService.download(this.item.content.content, this.item.content.name)
        .catch(e => {
          this.$message({
            message: 'There was an error downloading this media',
            type: 'error'
          });
        });
    },
    copyToClipboard() {
      CopyToClipboardService.copy(this.item.content.content)
        .then(val => {
          this.$message({
            showClose: true,
            message: 'Copied to clipboard'
          });
        })
        .catch(e => {
          this.$message({
            message: 'There was an error copying the link.',
            type: 'error'
          });
        });
    }
  }
};
</script>
<style lang="scss">
.ad-approval-row {
  &__affiliate-actions {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-copy-btn,
    &-edit-btn {
      padding: 0 $--clb-space-2;
    }

    &-submit {
      display: flex;

      &-cancel-btn {
        margin-right: $--clb-space-3;
      }

      @include xs-down {
        align-self: center;
      }

      align-self: flex-end;
    }
  }
}
</style>
