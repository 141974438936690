<template>
  <div class="ad-approval-layout">
    <portal to="ad-approval-new-request">
      <div v-if="campaignId" class="ad-approval-layout__heading-btns">
        <div v-if="isAffiliate && !isActiveTabSettings" class="ad-approval-request">
          <app-button
            class="ad-approval-request__btn sm:tw-bg-jb-indigo"
            title="Request Approval"
            :state="xsDown ? 'text' : 'primary'"
            :disabled="requestApproval"
            :text-color="xsDown ? requestApproval ? 'grey-light' :'primary' : 'white'"
            @click="handleRequestApprovalClick">
            <template v-if="!xsDown">
              <i class="el-icon-plus ad-approval-request__icon" />
              <span class="ad-approval-request__text"> Request Approval</span>
            </template>
            <i v-else class="el-icon-circle-plus ad-approval-request__icon tw-text-jb-indigo" />
          </app-button>
        </div>
      </div>
    </portal>
    <ad-approval-filters @nav-click="handleFiltersNavClick" />
    <div v-show="!isActiveTabSettings" class="ad-approval-layout__row">
      <el-tabs
        v-if="campaignId"
        ref="tabs"
        v-scrollable:x
        :value="activeTab"
        class="ad-approval-layout__tabs"
        :class="{ 'freeze': transition == '' }"
        :before-leave="handleBeforeLeaveTab"
        @tab-click="handleTabClick"
        @hook:mounted="addChangeListener">
        <el-tab-pane
          v-for="item in tabs"
          :key="`tab-${item.id}`"
          :label="`${item.name?item.name:item.id}`"
          :name="item.id"
          class="ad-approval-layout__tabs__list-item" />
      </el-tabs>
      <portal v-if="showStickyHeader" to="app-header-subcontent">
        <div
          id="ad-approval-layout-tabs-sink"
          ref="sink"
          class="ad-approval-layout"
          @click="clickDelegation" />
      </portal>
    </div>
    <portal-target v-if="showInnerFilters" name="ad-approval-inner-filters" />
    <transition name="slide">
      <div v-if="requestApproval && activeTab === 'pending'">
        <ad-approval-new-request @cancel="handleCancel" />
      </div>
    </transition>

    <transition :name="transition" mode="out-in">
      <ad-approval-settings v-if="isActiveTabSettings" :key="activeTab" :loading="loading" :creative-settings="creativeSettings" />
      <ad-approval-table v-else :key="activeTab" :loading="loading" :data="contentData" @get-contents="getContents" />
    </transition>
  </div>
</template>
<script>
import { AD_APPROVAL_TAB_DATA } from '@/constants/adApproval';
import AdApprovalNewRequest from '@/components/adApprovals/AdApprovalNewRequest';
import AdApprovalSettings from '@/views/adApproval/AdApprovalSettings';
import AdApprovalTable from '@/components/adApprovals/AdApprovalTable';
import AdApprovalMixin from '@/mixins/AdApprovalMixin';
import AdApprovalFilters from '@/components/adApprovals/AdApprovalFilters';

export default {
  name: 'AdApprovalLayout',
  components: { AdApprovalNewRequest, AdApprovalTable, AdApprovalSettings, AdApprovalFilters },
  mixins: [AdApprovalMixin],
  data() {
    return {
      loading: true,
      showStickyHeader: false,
      appHeaderHeight: 60,
      tabData: AD_APPROVAL_TAB_DATA,
      requestApproval: false,
      transition: '',
      activeTabIndex: 0,
      contentData: {
        data: [],
        pagination: { per_page: 16, page: 1 },
        sortBy: 'requested_at'
      },
      creativeSettings: {
        banned_words: {},
        response_time: '24',
        guidelines: ''
      }
    };
  },
  computed: {
    tabs() {
      return this.tabData[this.orgType];
    },
    shouldPreventNavigation() {
      return this.$store.getters.shouldPreventNavigation;
    },
    componentName() {
      if (this.isActiveTabSettings) return 'ad-approval-settings';
      return 'ad-approval-table';
    },
    showInnerFilters() {
      if (this.requestApproval) return false;
      if (this.activeTab === 'revoked') return this.actionBy ? true : this.contentData.data.length;
      if (this.activeTab === 'pending') return this.contentType ? true : this.contentData.data.length;
      return false;
    },
    triggerFetchContents() {
      return this.$store.getters.adTriggerFetchContents;
    }
  },
  watch: {
    campaignId: {
      immediate: true,
      async handler(nextCampaignId, prevCampaignId) {
        if (nextCampaignId) {
          await Promise.all([
            this.fetchCreativeSettings(nextCampaignId),
            this.getContents({ showLoader: true })
          ]);
          this.stickyHeader();
          scrollTo && scrollTo(0, 0);
        };
      }
    },
    triggerFetchContents(triggerFetchContents) {
      if (triggerFetchContents) this.getContents({ showLoader: false });
    },
    showStickyHeader: {
      immediate: true,
      handler() {
        this.$nextTick(this.stickyHeader);
      }
    },
    query(next, prev) {
      this.getContents();
    },
    activeTab() {
      if (!this.campaignId) return;
      const scrollY = window.pageYOffset || document.documentElement.scrollTop;
      const offset = Math.min(
        scrollY,
        scrollY + this.$refs.tabs.$el.getBoundingClientRect().top - this.appHeaderHeight
      );
      scrollTo && scrollTo(0, offset);
      this.$nextTick(this.stickyHeader);
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, this.$supportsPassive && { passive: true });
    // The header loads too slow and the sticky header will appear
    this.$nextTick(this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, this.$supportsPassive && { passive: true });
  },
  methods: {
    async fetchCreativeSettings(campaignId) {
      try {
        this.isLoading = true;
        const params = {
          campaignId: campaignId
        };
        const response = await this.$api.adApprovals.fetchCreativeSettings({
          storeAction: 'setAdBannedWords',
          ...params
        });
        response.banned_words = response.banned_words.reduce((acc, val) => {
          acc[val] = true;
          return acc;
        }, {});
        this.creativeSettings = response;
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.loading = false;
      }
    },
    async getContents(params = { showLoader: true, pagination: {}, sortBy: 'requested_at' }) {
      try {
        if (params.showLoader) this.loading = true;
        const status = this.$route.params.tab;
        const response = await this.$api.adApprovals.fetchContents({
          campaignId: this.campaignId,
          params: {
            ...this.dateFilter,
            ...params.pagination,
            sortBy: params.sortBy,
            filter: { status, affiliate_id: this.affiliateId, content_type: this.contentType, action_by: this.actionBy }
          },
          fullResponse: true,
          storeAction: 'fetchContents'
        });
        this.contentData = { data: response.data, pagination: response._meta, sortBy: params.sortBy };
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.loading = false;
      }
    },
    handleCancel() {
      this.requestApproval = false;
    },
    handleBeforeLeaveTab(activeName, oldActiveName) {
      if (this.shouldPreventNavigation) return Promise.reject(new Error('shouldPreventNavigation'));
      return Promise.resolve();
    },
    handleRequestApprovalClick() {
      this.$router.push({
        name: 'dashboard-campaign-setup-approvals',
        params: { tab: 'pending', campaignId: this.campaignId },
        query: this.query
      });
      this.requestApproval = true;
    },
    handleTabClick({ name, index }) {
      if (!this.shouldPreventNavigation) this.requestApproval = false;
      this.transition = index > this.activeTabIndex ? 'slide-left' : 'slide-right';
      this.$router.push({
        name: 'dashboard-campaign-setup-approvals',
        params: { tab: name, campaignId: this.campaignId },
        query: this.query
      });
      this.activeTabIndex = index;
    },
    handleFiltersNavClick(pathName) {
      const index = pathName === 'settings' ? this.tabs.length : this.tabs.findIndex(item => { return item.name === pathName; });
      this.handleTabClick({ name: pathName, index: index });
    },
    handleScroll() {
      try {
        this.showStickyHeader = !this.isActiveTabSettings && this.$refs.tabs.$el.getBoundingClientRect().top <= this.appHeaderHeight;
      } catch (e) {
        this.showStickyHeader = false;
      }
    },
    addChangeListener() {
      try {
        const ref = this.$refs.tabs.$refs.nav;
        if (!ref) return;

        ref.$on('hook:created', this.stickyHeader);
        ref.$on('hook:updated', this.stickyHeader);
      } catch (e) {}
    },
    // eslint-disable-next-line sonarjs/cognitive-complexity
    stickyHeader() {
      if (!this.$refs || !this.$refs.tabs || !this.showStickyHeader) return;

      const ref = this.$refs.sink;
      const elm = this.$refs.tabs.$el;

      if (ref) {
        // Remove existing children
        while (ref.firstChild) {
          ref.removeChild(ref.firstChild);
        }

        // Append header
        if (elm) {
          const clone = elm.cloneNode(true);
          ref.appendChild(clone);

          // Keep scroll positions in sync
          if (clone.querySelector('.el-tabs__nav-scroll')) {
            const scrollables = [
              clone.querySelector('.el-tabs__nav-scroll'),
              elm.querySelector('.el-tabs__nav-scroll')
            ];
            scrollables[0].scrollLeft = scrollables[1].scrollLeft;
            scrollables[0].addEventListener(
              'scroll',
              () => {
                scrollables[1].scrollLeft = scrollables[0].scrollLeft;
              },
              this.$supportsPassive && { passive: true }
            );
          }
        }
      }
    },
    /* eslint complexity: ["error", 10] */
    clickDelegation(e) {
      try {
        const nav = this.$refs.sink.querySelector('.el-tabs__nav');
        const actions = this.$refs.sink.querySelector('.el-tabs__nav-wrap');
        let target = e.target || e.srcElement;
        let index;

        // Target is a tab
        if (nav.contains(target)) {
          // Find the tab that the event was triggered from inside
          while (target.nav !== nav && target.nav) target = target.nav;
          // Figure out what the tab index is
          for (index = -1; (target = target.previousSibling) != null; index++);
          this.handleTabClick({ index: index + '', name: this.tabs[index].id });

          // Target is a tab scroll
        } else if (actions.contains(target)) {
          const selector = '.' + target.className.replace(/\s+/g, ' ').trim().split(' ')[0];
          this.$refs.tabs.$el.querySelector(selector).click();
        }
      } catch (e) {}
    }
  }
};
</script>

<style lang="scss">
.ad-approval-layout {
  @include stage-layout;

  @apply tw-mt-0;

  &__heading {
    &-btns {
      display: flex;

      .ad-approval-request {
        &__text {
          margin-left: $--clb-space-4;
        }

        @include xs-down {
          &__btn {
            font-size: $--clb-layout-2;
            color: $--clb-color-primary;
            padding: $--clb-space-1;
            padding-top: 0;
          }
        }
      }

      .ad-approval-settings-btn {
        font-size: $--clb-layout-2;

        @include xs-down {
          padding: $--clb-space-1;
          padding-top: 0;
          padding-left: $--clb-space-4;
        }
      }
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap-reverse;
  }

  &__tabs {
    width: 100%;
    z-index: 0;

    .el-tabs__header {
      margin-bottom: $--clb-space-2;
    }

    .el-tabs__active-bar {
      @apply tw-bg-jb-indigo;
    }

    .el-tabs__item {
      height: $--clb-layout-4;
      line-height: $--clb-layout-4;
      font-size: $--clb-font-size-base;
      color: $--clb-color-grey__grey-darker;

      &.is-active {
        color: $--clb-color__headings;
      }
    }

    .el-tabs__nav-next,
    .el-tabs__nav-prev {
      line-height: $--clb-layout-4;
    }

    .el-tabs__nav {
      .el-tabs__active-bar {
        height: 3px;
        transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s ease-in-out;
      }
    }

    .el-tabs__nav-wrap::after {
      height: 1px;
    }

    &.freeze {
      .el-tabs__active-bar {
        transition: none;
      }
    }
  }

  .ad-approval-request {
    &__text {
      margin-left: $--clb-space-4;
    }

    @include xs-down {
      &__btn {
        font-size: $--clb-layout-2;
        color: $--clb-color-primary;
        padding: $--clb-space-1;
        padding-top: 0;
      }
    }
  }

  .ad-approval-settings-btn {
    font-size: $--clb-layout-2;

    @include xs-down {
      padding: $--clb-space-1;
      padding-top: 0;
      padding-left: $--clb-space-4;
    }
  }

  .slide-enter-to,
  .slide-leave {
    max-height: 600px;
    transition: max-height 0.5s ease-out;
    overflow: hidden;
  }

  .slide-enter,
  .slide-leave-to {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-out;
  }
}

.app-header__portal .ad-approval-layout {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: none;

  &__tabs {
    transition: $--all-transition;

    .el-tabs__header {
      margin-bottom: 0;
    }

    @apply xs:tw-px-layout-1 sm:tw-px-layout-4 md:tw-px-layout-3 lg:tw-px-layout-5;
  }
}
</style>
