const downloadMedia = (content, name) => {
  return new Promise((resolve, reject) => {
    const download = document.createElement('a');
    download.href = content;
    download.download = name.replace(/[^a-zA-Z0-9 -]/, '');
    download.target = '_blank';
    document.body.appendChild(download);
    download.click();
    document.body.removeChild(download);
    resolve();
  });
};

export default {
  download: downloadMedia
};
