<template>
  <div
    class="ad-approval-history"
    :class="{
      ['ad-approval-history--affiliate']: isAffiliate,
      ['ad-approval-history--loading']: loading,
      ['ad-approval-history--empty']: history.length === 0
    }">
    <!-- loading -->
    <div v-if="loading" class="tw-flex">
      <div class="tw-pr-layout-1" :style="{ width: colWidth, minWidth: colWidth }">
        <skeleton-box width="100%" height="16px" />
      </div>
      <div v-if="!isAffiliate && smUp" class="tw-pr-layout-1" :style="{ width: colWidth, minWidth: colWidth }">
        <skeleton-box width="100%" height="16px" />
      </div>
      <div class="tw-w-full">
        <skeleton-box width="90%" height="16px" class="tw-mb-space-2" />
        <skeleton-box width="80%" height="16px" />
      </div>
    </div>

    <!-- empty -->
    <div v-else-if="history.length === 0" class="tw-text-center">
      <app-text weight="medium" color="grey-dark">
        <i class="el-icon-warning-outline" />
        No history for this request
      </app-text>
    </div>

    <!-- data -->
    <div v-else class="tw-flex tw-flex-col">
      <app-text class="ad-approval-history__title" type="div" size="xs" weight="medium" color="default">
        Revision History
      </app-text>

      <div class="ad-approval-history__rows">
        <div v-for="row in history" :key="`ad-approval-history-${row.request_id}-${row.version}`" class="ad-approval-history__inner">
          <ad-approval-row-content :key="`ad-approval-history-content-${row.request_id}-${row.version}`" :item="row" @handle-image-click="handleImageClick">
            <template v-slot:date="{ date }">
              <app-text v-if="!hasNotes(row)" size="xs" color="grey-dark">
                {{ date | capitalize }}
              </app-text>
              <span v-else />
            </template>

            <template v-slot:action-name="{ actionName }">
              <app-text v-if="!hasNotes(row)" size="xs" color="grey-dark">
                {{ actionName }}
              </app-text>
              <span v-else />
            </template>

            <template v-slot:text-content>
              <app-text
                type="div"
                :size="smUp ? 'md' : 'sm'"
                weight="medium"
                color="grey-darker"
                class="tw-whitespace-pre-line"
                :style="{ wordBreak: 'break-word' }">
                {{ row.content.content }}
              </app-text>
            </template>
          </ad-approval-row-content>

          <div v-for="note in row.notes" :key="`ad-approval-history-note-${note.request_id}-${note.version}`" class="ad-approval-history__inner-row tw-flex tw-items-stretch">
            <ad-approval-row-content :key="`ad-approval-history-note-content-${note.request_id}-${note.version}`" :item="note" class="tw-mb-space-1 tw-items-stretch" @handle-image-click="handleImageClick">
              <template v-slot:date="{ date }">
                <app-text size="xs" color="grey-dark">
                  {{ date | capitalize }}
                </app-text>
              </template>

              <template v-slot:action-name="{ actionName }">
                <app-text size="xs" color="grey-dark">
                  {{ actionName }}
                </app-text>
              </template>

              <template v-slot:text-content>
                <app-text
                  type="div"
                  :size="smUp ? 'sm' : 'xs'"
                  color="grey-dark"
                  class="ad-approval-history__inner-note tw-whitespace-pre-line"
                  :style="{ wordBreak: 'break-word' }">
                  {{ note.note }}
                </app-text>
              </template>

              <template v-slot:visual-content>
                <app-text
                  type="div"
                  :size="smUp ? 'sm' : 'xs'"
                  color="grey-dark"
                  class="ad-approval-history__inner-note tw-whitespace-pre-line"
                  :style="{ wordBreak: 'break-word' }">
                  {{ note.note }}
                </app-text>
              </template>
            </ad-approval-row-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import _cloneDeep from 'lodash/cloneDeep';
import _groupBy from 'lodash/groupBy';
import _orderBy from 'lodash/orderBy';
import SkeletonBox from '@/components/skeletons/SkeletonBox';
import AdApprovalRowContent from '@/components/adApprovals/adApprovalRow/AdApprovalRowContent';
import AdApprovalMixin from '@/mixins/AdApprovalMixin';
import imageService from '@/services/image-service';

export default {
  name: 'AdApprovalHistory',
  components: { SkeletonBox, AdApprovalRowContent },
  mixins: [AdApprovalMixin],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      history: []
    };
  },
  created() {
    this.getHistory();
  },
  methods: {
    async getHistory() {
      try {
        this.loading = true;
        const response = await this.$api.adApprovals.fetchContentHistory({
          campaignId: this.campaignId,
          requestId: this.item.request_id
        });
        const history = Object.entries(_groupBy(_cloneDeep(response), (row) => row.content.id)).map(([key, rows]) => {
          const row = rows[rows.length - 1];
          row.notes = _orderBy(_cloneDeep(rows), 'version', 'desc')
            .map(({ notes, ...rest }) => ({ ...rest, note: notes }))
            .filter((row) => row.note);
          return row;
        });
        this.history = history.reverse(); // Sort by descending order
      } catch (error) {
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.loading = false;
      }
    },
    resizeImage(image, options = {}) {
      return imageService.resizeImage(image, {
        w: 40,
        h: 40,
        auto: 'enhance,format',
        fit: 'crop',
        crop: 'top',
        bg: 'efefef',
        fm: 'jpeg',
        q: 85,
        ...options
      });
    },
    formatTime(time) {
      const contentDate = dayjs(time);
      if (dayjs(contentDate).isValid()) return dayjs(contentDate).fromNow();
      return 'unknown';
    },
    isVisualContent(row) {
      return row.content.type === 'visual';
    },
    hasNotes(row) {
      return row.notes.length > 0;
    },
    getActionName(row) {
      if (this.isAdvertiser && row.requested_by) return row.requested_by.name || row.requested_by.id;
      return row.action_by.name || row.action_by.id;
    },
    handleImageClick(...args) {
      this.$emit('handle-image-click', ...args);
    }
  }
};
</script>

<style lang="scss">
.ad-approval-history {
  padding: 0 $--clb-layout-1;
  background-color: $--clb-color-grey__white-ter;

  &--loading,
  &--empty {
    padding: $--clb-layout-1;
  }

  &:not(&--affiliate) {
    padding-left: $--clb-layout-2;

    .ad-approval-history__title {
      margin-top: $--clb-layout-2 !important;
    }
  }

  &--loading {
    svg.skeleton-box {
      margin: 0;
    }
  }

  &__title {
    margin-top: $--clb-space-3 !important;
    margin-bottom: $--clb-space-3 !important;
    color: $--clb-color-grey__grey-dark !important;

    @include sm-up {
      margin-bottom: $--clb-space-1 !important;
    }
  }

  &__inner {
    padding: $--clb-space-3 0;
    border-top: 1px solid $--clb-color-grey__grey-lighter;

    @include sm-up {
      padding: $--clb-space-4 0;
    }

    &:first-child {
      padding-top: 0;
      border-top: none;
    }

    .ad-approval-row-content__info {
      align-items: stretch !important;
    }

    .ad-approval-row-content__date-wrapper,
    .ad-approval-row-content__action-wrapper {
      @include xs-down {
        display: none;
      }
    }

    &-note {
      height: 100%;
      position: relative;
      padding-left: 20px;

      &::before {
        content: '';
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><circle fill="%23cccfdb" cx="16" cy="16" r="10"/></svg>');
        position: absolute;
        left: 0;
        top: 4px;
        height: 12px;
        width: 12px;
      }
    }

    &-row {
      &:nth-child(2) {
        margin-top: $--clb-space-3;
      }

      &:not(:last-child) {
        .ad-approval-history__inner-note {
          &::after {
            content: '';
            background-color: $--clb-color-grey__grey-lighter;
            position: absolute;
            bottom: -6px;
            top: 16px;
            left: 4px;
            width: 2px;
          }
        }
      }
    }
  }
}
</style>
