<template>
  <div class="ad-approval-row" :class="{'selected': highlightedRowId === item.request_id}">
    <div class="ad-approval-row__inner">
      <div v-if="!isAffiliate" class="ad-approval-row__inner-tag" :class="{['ad-approval-row__inner-tag-color-' + dateStatus.color]: item.requested_at}" />
      <div class="ad-approval-row__main">
        <div class="ad-approval-row__content">
          <!--  Left Side Content -->
          <ad-approval-row-content :item="item" @handle-image-click="handleImageClick">
            <template v-slot:date>
              <app-text size="sm">
                {{ isTimeAgo ? dateStatus.dateStatus : timeAgo | capitalize }}
              </app-text>
            </template>

            <template v-slot:mobile-actions>
              <span class="tw-flex tw-ml-auto">
                <portal-target :name="`ad-approval-note-icon-${_uid}`" />
                <portal-target v-if="!affiliateIsEditing" :name="`ad-approval-affiliate-actions-${_uid}`" />
                <portal-target :name="`ad-approval-history-toggle-${_uid}`" />
              </span>
            </template>

            <!-- Edit (Middle) Section -->
            <template v-slot:edit-content>
              <transition name="slide">
                <div v-if="affiliateIsEditing || advertiserIsEditing" class="ad-approval-row__content__info-text__edit tw-mt-space-2">
                  <template v-if="affiliateIsEditing && isVisualContent">
                    <div class="ad-approval-row__content__info-text__edit-input">
                      <el-form ref="form" :model="data">
                        <ad-approval-visual-content
                          :images="data.images"
                          :multiple="false"
                          :meta="imageMeta"
                          :max-count="1"
                          @update-image="updateImage"
                          @set-images="setImages"
                          @delete-image="deleteImage" />
                      </el-form>
                    </div>
                  </template>
                  <template v-else>
                    <div class="ad-approval-row__content__info-text__edit-input">
                      <app-text v-if="!isAffiliate" weight="lighter" color="grey-dark" size="xs">
                        {{ advertiserInputLabel }}
                      </app-text>
                      <el-input
                        v-model="data.editedItem.content"
                        :autosize="true"
                        type="textarea"
                        size="mini" />
                      <div v-if="isAffiliate" class="add-note-section">
                        <div v-tippy="{ enabled: true, showOnInit: false, content: tooltipContent, arrow: true, followCursor: false }" class="add-note-section__note-icon">
                          <i class="el-icon-document" />
                        </div>
                        <transition name="fade" mode="out-in">
                          <div v-if="!data.editedItem.hasNote" key="addnotebtn">
                            <app-button
                              class="add-note-btn"
                              size="mini"
                              text-color="primary"
                              state="text"
                              @click="handleAddNote(true)">
                              Add Note for Advertiser
                            </app-button>
                          </div>
                          <div v-else key="addnoteinput" class="add-note-section__edit">
                            <div class="add-note-section__edit__input">
                              <el-input
                                v-model="data.editedItem.notes"
                                placeholder="Context for the request (ex. channel, geo...)"
                                size="mini" />
                            </div>
                            <div>
                              <app-button
                                weight="semi-bold"
                                state="text"
                                class="delete-note-btn"
                                @click="handleAddNote(false)">
                                <i class="el-icon-delete" />
                              </app-button>
                            </div>
                          </div>
                        </transition>
                      </div>
                    </div>
                  </template>
                  <div v-if="item.notes" class="ad-approval-row__content__info-text__edit-note">
                    <div class="ad-approval-row__content__info-text__edit-note-title">
                      <app-text weight="lighter" color="grey-dark" size="xs">
                        {{ isAffiliate ? 'Revision Note' : 'Affiliate Note' }}
                      </app-text>
                      <app-text color="grey-darker" weight="lighter">
                        {{ item.notes }}
                      </app-text>
                    </div>
                  </div>
                </div>
              </transition>
            </template>
          </ad-approval-row-content>

          <!--  Right Side Actions -->
          <portal-target v-if="!xsDown" :name="`ad-approval-note-icon-${_uid}`" />
          <advertiser-actions
            v-if="!isAffiliate"
            :edited-item="data.editedItem"
            :item="item"
            :submit-loading="submitLoading"
            :advertiser-is-editing="advertiserIsEditing"
            @put-content="putContent"
            @rejection-type="handleRejectionType"
            @changeEditState="handleChangeAdvertiserEditState" />
          <portal-target v-if="!xsDown || affiliateIsEditing" :name="`ad-approval-affiliate-actions-${_uid}`" />
          <portal-target v-if="!xsDown" :name="`ad-approval-history-toggle-${_uid}`" />
        </div>
      </div>
    </div>

    <app-lightbox
      v-if="lightboxVisible && lightboxImage"
      :is-visible="lightboxVisible"
      :is-video="lightboxVideo"
      :image-url="lightboxImage"
      :navigate="false"
      @close="lightboxVisible = false" />

    <portal :to="`ad-approval-affiliate-actions-${_uid}`">
      <affiliate-actions
        v-if="isAffiliate"
        :edited-item="data.editedItem"
        :visual-content="data.images"
        :submit-loading="submitLoading"
        :item="item"
        :affiliate-is-editing="affiliateIsEditing"
        @put-content="putContent"
        @changeEditState="handleChangeAffiliateEditState" />
      <div v-if="showRevokeBtn" class="ad-approval-row__content__revoke">
        <app-button tippy="Revoke" class="ad-approval-row__content__revoke-btn" state="text" @click="showRevokeModal">
          <i class="el-icon-close" />
        </app-button>
      </div>
    </portal>
    <portal :to="`ad-approval-history-toggle-${_uid}`">
      <div class="ad-approval-row__content__history-toggle">
        <div class="toggle-seperator" />
        <div class="toggle tw-block">
          <app-button class="toggle-btn" state="text" @click="handleHistoryToggle">
            <i class="el-icon-arrow-down" :class="{'active': isHistoryView}" />
          </app-button>
        </div>
      </div>
    </portal>
    <portal :to="`ad-approval-note-icon-${_uid}`">
      <div class="ad-approval-row__content__review">
        <app-button v-if="item.notes && !(affiliateIsEditing || advertiserIsEditing)" :tippy="item.notes" class="ad-approval-row__content__review-btn" state="text">
          <i class="el-icon-document" />
        </app-button>
      </div>
    </portal>
    <transition name="slide">
      <ad-approval-history v-if="isHistoryView" :item="item" @handle-image-click="handleImageClick" />
    </transition>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import AppLightbox from '@/components/AppLightbox';
import AdApprovalHistory from '@/components/adApprovals/adApprovalRow/AdApprovalHistory';
import AdvertiserActions from '@/components/adApprovals/adApprovalRow/actions/AdvertiserActions';
import AffiliateActions from '@/components/adApprovals/adApprovalRow/actions/AffiliateActions';
import AdApprovalRowContent from '@/components/adApprovals/adApprovalRow/AdApprovalRowContent';
import AdApprovalVisualContent from '@/components/adApprovals/AdApprovalVisualContent';
import AdApprovalMixin from '@/mixins/AdApprovalMixin';
import imageService from '@/services/image-service';

export default {
  name: 'AdApprovalrow',
  components: { AppLightbox, AdApprovalHistory, AdvertiserActions, AffiliateActions, AdApprovalRowContent, AdApprovalVisualContent },
  mixins: [AdApprovalMixin],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      lightboxVisible: false,
      affiliateIsEditing: false,
      advertiserIsEditing: false,
      isHistoryView: false,
      highlightedRowId: null,
      submitLoading: false,
      advertiserInputLabel: 'Approved Edited Content',
      tooltipContent: 'Give the Advertiser more context to approve your request by providing information about where and how it will be run',
      data: {
        images: [],
        editedItem: {
          hasNote: false,
          notes: '',
          content: ''
        }
      }
    };
  },
  computed: {
    actionBy() {
      if (this.item.status === 'approved') {
        if (this.isAdvertiser) return this.item.requested_by.id;
        if (this.isInternal) return this.item.requested_by.name;
      }
      return this.item.action_by.name || this.item.action_by.id;
    },
    dateStatus() {
      const now = dayjs(dayjs().format('YYYY-MM-DD'));
      const contentDate = dayjs(dayjs(this.item.requested_at).format('YYYY-MM-DD'));
      if (contentDate.isSame(now)) return { color: 'orange', dateStatus: 'today' };
      if (contentDate.isBefore(now)) return { color: 'red', dateStatus: 'overdue' };
      if (contentDate.isAfter(now)) return { color: 'green', dateStatus: 'tomorrow' };
      return { color: 'orange', dateStatus: 'unknown' };
    },
    isTimeAgo() {
      return !this.isAffiliate && this.item.status !== 'approved';
    },
    timeAgo() {
      const contentDate = dayjs(this.item.requested_at);
      if (dayjs(contentDate).isValid()) return dayjs(contentDate).fromNow();
      return 'unknown';
    },
    selectedRowId() {
      return this.$store.getters.adSelectedApprovalId;
    },
    isVisualContent() {
      return this.item.content.type === 'visual';
    },
    imageMeta() {
      return { orgId: this.orgId, campaignId: this.campaignId };
    },
    showRevokeBtn() {
      if (this.affiliateIsEditing || this.advertiserIsEditing) return false;
      if (!this.isAffiliate && this.activeTab === 'approved') return true;
      if (this.isAffiliate && ['approved', 'revoked'].indexOf(this.activeTab) === -1) return true;
      return false;
    }
  },
  created() {
    this.data.editedItem.content = this.item.content.content;
  },
  methods: {
    resizeImage(image, options = {}) {
      return imageService.resizeImage(image, {
        w: 40,
        h: 40,
        auto: 'enhance,format',
        fit: 'crop',
        crop: 'top',
        bg: 'efefef',
        fm: 'jpeg',
        q: 85,
        ...options
      });
    },
    handleImageClick(image, isVideo = false) {
      this.lightboxImage = image;
      this.lightboxVideo = isVideo;
      this.lightboxVisible = true;
    },
    handleAddNote(mode) {
      this.data.editedItem.hasNote = mode;
    },
    handleRejectionType(value) {
      if (value === 'approve-with-edits') {
        this.advertiserInputLabel = 'Approved Edited Content';
        this.data.editedItem.content = this.item.content.content;
      } else {
        this.advertiserInputLabel = 'Rejection Feedback Notes';
        this.data.editedItem.content = '';
      }
    },
    handleChangeAdvertiserEditState(isEditing) {
      this.data.editedItem.content = isEditing ? this.item.content.content : '';
      this.advertiserIsEditing = isEditing;
    },
    handleChangeAffiliateEditState(isEditing) {
      this.data.editedItem.content = isEditing ? this.item.content.content : '';
      this.affiliateIsEditing = isEditing;
    },
    handleHistoryToggle() {
      this.isHistoryView = !this.isHistoryView;
    },
    setImages(images) {
      this.$set(this.data, 'images', images);
    },
    deleteImage(index) {
      this.$set(this.data, 'images', []);
    },
    updateImage({ index, image }) {
      this.$set(this.data.images, index, image);
    },
    highlightRow(isHighlighted) {
      this.highlightedRowId = isHighlighted ? this.item.request_id : null;
    },
    getPayload(action) {
      const payload = { action, type: this.item.content.type };

      switch (action) {
        case 'request':
          if (this.data.editedItem.hasNote) payload.notes = this.data.editedItem.notes;
          if (this.isVisualContent) return { ...payload, ...this.data.images[0] };
          return { ...payload, content: this.data.editedItem.content };
        case 'approve':
          return { ...payload, content: this.item.content.content };
        case 'approveWithEdits':
          return { ...payload, action: 'approve', content: this.data.editedItem.content };
        case 'revoke':
          return { ...payload, notes: this.data.editedItem.notes };
        case 'reject':
          return { ...payload, notes: this.data.editedItem.content };
      }
    },
    async putContent(action) {
      try {
        this.submitLoading = true;
        await this.$api.adApprovals.putContent({
          params: this.getPayload(action),
          campaignId: this.campaignId,
          requestId: this.item.request_id,
          storeAction: 'putContent'
        });
        this.affiliateIsEditing = false;
        this.advertiserIsEditing = false;
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        if (this.$api.hasErrors(error)) {
          error.response.data._meta.errors.forEach((message, index) => {
            // allow enough time for previous message to animate in
            setTimeout(() => this.$message({ message, type: 'error' }), index * 300);
          });
          return;
        }
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.submitLoading = false;
      }
    },
    async deleteContent() {
      try {
        await this.$api.adApprovals.deleteContent({
          campaignId: this.campaignId,
          requestId: this.item.request_id,
          storeAction: 'deleteContent'
        });
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        if (this.$api.hasErrors(error)) {
          error.response.data._meta.errors.forEach((message, index) => {
            // allow enough time for previous message to animate in
            setTimeout(() => this.$message({ message, type: 'error' }), index * 300);
          });
          return;
        }
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.submitLoading = false;
        this.highlightRow(false);
      }
    },
    showRevokeModal() {
      this.highlightRow(true);
      this[this.isAffiliate ? '$confirm' : '$prompt']('Are you sure you want to revoke this request? This action cannot be undone.', 'Revoke Request', {
        confirmButtonText: 'Revoke',
        inputPlaceholder: 'Reason for revoking approval',
        customClass: 'ad-approval-row__message-box',
        cancelButtonText: 'Cancel',
        inputValidator: (value) => {
          if (!value || !value.length) return 'Note is required';
          return true;
        },
        type: 'warning'
      }).then(({ value }) => {
        if (this.isAffiliate) return this.deleteContent();
        this.data.editedItem.notes = value;
        this.putContent('revoke');
      }).catch(() => {
        this.highlightRow(false);
      });
    }
  }
};
</script>

<style lang="scss">
.ad-approval-row {
  border: 1px solid $--clb-border-color-base;
  border-radius: $--clb-border-radius;
  width: 100%;
  font-weight: $--clb-font-weight__semi-bold;
  margin-bottom: $--clb-space-2;
  overflow-x: auto;

  &__ad-approval-history {
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.26s ease;

    &.active {
      transform: scaleY(1);
    }
  }

  &__affiliate-actions-review-btn {
    padding: 0;
    display: flex;
    align-items: center;
    height: 100% !important;
  }

  &.selected {
    background: $--clb-color-primary__lighter;
  }

  &__inner {
    display: flex;

    &-tag {
      min-width: 2px;
      margin: $--clb-space-2  0 $--clb-space-2 #{$--clb-space-2 - 2px};
      border-radius: $--clb-border-radius;

      &-color {
        &-red {
          background-color: $--clb-color-red;
        }

        &-orange {
          background-color: $--clb-color-warning;
        }

        &-green {
          background-color: $--clb-color-green;
        }
      }
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__content {
    width: 100%;
    padding: $--clb-space-4;
    line-height: initial;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include xs-down {
      flex-wrap: wrap;
      flex-direction: row-reverse;
    }

    .ad-approval-row-content__info {
      @include xs-down {
        padding-bottom: $--clb-space-3;
        margin-bottom: $--clb-space-3;
        border-bottom: $--clb-border-complete-light;
      }
    }

    .vue-portal-target {
      display: flex;
    }

    &__review,
    &__revoke {
      display: flex;
      align-items: center;

      &-btn {
        padding: 0 $--clb-space-2;
      }
    }

    &__history-toggle {
      display: flex;
      align-items: center;
      margin-left: $--clb-space-4;

      .toggle {
        margin-left: $--clb-space-4;

        &-btn {
          padding: 0;

          i {
            transition: all 0.4s ease;

            &.active {
              transform: rotateZ(-180deg);
              transform-origin: center;
            }
          }
        }

        &-seperator {
          background-color: $--clb-color-grey__grey-lighter;
          width: 1px;
          height: 100%;
        }
      }
    }

    .add-note {
      &-section {
        display: flex;
        align-items: center;
        min-height: 40px;
        padding-top: $--clb-space-1;
        margin-bottom: $--clb-space-4;

        &__note-icon {
          padding: 0 $--clb-space-2;
        }

        &__edit {
          display: flex;
          align-items: center;
          width: 100%;

          &__input {
            flex-grow: 1;
          }
        }

        .delete-note-btn,
        .delete-btn {
          padding: 0;
          margin-left: $--clb-space-4;
        }
      }

      &-btn {
        padding: 0;
      }
    }
  }

  &__message-box {
    .el-input__inner {
      font-size: $--clb-font-size-base;
      height: 35px; // mini
      min-height: 35px !important;
    }
  }
}
</style>
