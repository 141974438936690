<template>
  <div class="ad-approval-row__advertiser-actions">
    <template v-if="!advertiserIsEditing">
      <template v-if="showActions">
        <app-button class="ad-approval-row__advertiser-actions__btns reject-btn" size="micro" state="secondary" @click="handleRejectClick">
          Reject
        </app-button>
        <app-button
          :disabled="submitLoading"
          :loading="submitLoading"
          class="ad-approval-row__advertiser-actions__btns approve-btn"
          size="micro"
          state="primary"
          @click="approveContent">
          Approve
        </app-button>
      </template>
    </template>
    <template v-else>
      <div class="ad-approval-row-switch">
        <div v-if="canApproveWithEdits" class="ad-approval-row-switch-toggle">
          <div :class="{['active'] : toggleView === 'approve-with-edits'}" class="ad-approval-row-switch-toggle__btn approve-with-edits">
            Approve with edits
          </div>
          <div :class="{['active'] : toggleView === 'reject-with-notes'}" class="ad-approval-row-switch-toggle__btn reject-with-notes">
            Reject with notes
          </div>

          <el-switch
            v-model="approveWithEdits"
            active-color="#0ca678"
            inactive-color="#f03e3e"
            @change="changeToggleView($event)" />
        </div>
        <div class="submit-btn">
          <app-button
            class="ad-approval-row__advertiser-actions__btns reject-btn"
            size="micro"
            state="secondary"
            @click="handleCancelEditClick">
            Cancel
          </app-button>
          <app-button
            class="ad-approval-row__advertiser-actions__btns approve-btn"
            :loading="submitLoading"
            :disabled="submitIsDisabled"
            :state="toggleView === 'approve-with-edits' && canApproveWithEdits ? 'green' : 'red'"
            size="micro"
            @click="rejectContent">
            {{ toggleView === 'approve-with-edits' && canApproveWithEdits ? 'Submit' : 'Reject' }}
          </app-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import AdApprovalMixin from '@/mixins/AdApprovalMixin';

export default {
  name: 'AdvertiserActions',
  mixins: [AdApprovalMixin],
  props: {
    item: {
      type: Object,
      required: true
    },
    advertiserIsEditing: {
      type: Boolean,
      required: true
    },
    editedItem: {
      type: Object,
      required: true
    },
    submitLoading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      toggleView: 'reject-with-notes',
      approveWithEdits: false
    };
  },
  computed: {
    isVisualContent() {
      return this.item.content.type === 'visual';
    },
    showActions() {
      return !['approved', 'waiting_approval', 'waiting_revision'].includes(this.activeTab);
    },
    canApproveWithEdits() {
      return !this.isVisualContent && this.orgType !== 'employee';
    },
    rejectAction() {
      if (!this.canApproveWithEdits) return 'reject';
      return this.toggleView === 'approve-with-edits' ? 'approveWithEdits' : 'reject';
    },
    submitIsDisabled() {
      if (this.toggleView === 'reject-with-notes') return !this.editedItem.content || this.item.content.notes;
      return !this.editedItem.content || !this.item.content.content || this.item.content.content === this.editedItem.content;
    }
  },
  methods: {
    handleRejectClick() {
      this.$emit('changeEditState', true);
      this.$emit('rejection-type', this.toggleView);
    },
    handleCancelEditClick() {
      this.$emit('changeEditState', false);
    },
    changeToggleView(val) {
      this.toggleView = val === true ? 'approve-with-edits' : 'reject-with-notes';
      this.$emit('rejection-type', this.toggleView);
    },
    approveContent() {
      this.$emit('put-content', 'approve');
    },
    rejectContent() {
      this.$emit('put-content', this.rejectAction);
    }
  }
};
</script>

<style lang="scss">
.ad-approval-row {
  &__advertiser-actions {
    display: flex;
    flex-direction: row;
    font-size: $--clb-font-size-xl;
    align-items: center;
    justify-content: flex-end;

    @include xs-down {
      padding-top: $--clb-space-2;
    }

    &__btns {
      margin-left: $--clb-space-2;

      &:first-child {
        margin-left: 0;
      }

      &.review-btn {
        padding: 0 $--clb-space-1;
      }

      &.approve-btn,
      &.reject-btn {
        width: 80px;
      }
    }
  }

  &-switch {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: $--clb-layout-6;
    justify-content: space-between;

    .submit-btn {
      display: flex;
      align-self: flex-end;
      margin-top: auto;
    }

    &-toggle {
      text-align: right;
      position: relative;
      min-width: 200px;
      padding-top: $--clb-space-3;

      &__btn {
        color: $--clb-color-grey__grey;
        font-size: $--clb-font-size-sm;
        transition: all 0.3s ease-in;
        font-weight: $--clb-font-weight__normal;
        position: absolute;

        &.approve-with-edits {
          right: 40px;
          top: 2px;

          &.active {
            color: $--clb-color-green-darker;
          }
        }

        &.reject-with-notes {
          position: absolute;
          right: 40px;
          top: 25px;

          &.active {
            color: $--clb-color-red-darker;
          }
        }

        &.active {
          color: $--clb-color-primary;
          font-weight: $--clb-font-weight__bold;
        }
      }

      .el-switch__core {
        transform: rotate(270deg);
      }
    }
  }
}
</style>
