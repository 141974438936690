<template>
  <div class="ad-approval-response-time">
    <div class="ad-approval-response-time__content">
      <div class="ad-approval-response-time__content-header">
        <app-heading level="h2" size="h4" weight="semi-bold">
          Response Time
        </app-heading>
        <app-text type="p" class="ad-approval-response-time__content-header__instructions">
          The amount of time the organization commits to responding to a creative approval request. Response time cannot exceed 48 hours.
        </app-text>
      </div>
      <div class="ad-approval-response-time__content__input" :class="{'affiliate-spacing': isAffiliate}">
        <template v-if="loading">
          <div class="tw-flex tw-items-center">
            <skeleton-box width="57px" height="60px" />
            <skeleton-box width="63px" height="16px" class="tw-ml-layout-1" />
          </div>
        </template>
        <template v-else>
          <el-input
            v-if="!isAffiliate"
            ref="elInput"
            v-model="responseTime"
            class="el-input"
            type="number"
            autocomplete="no"
            maxlength="2"
            :class="{['border-success']:responseTimeSaved, ['border-error']: maxNumber || invalidEntry}"
            @change="saveResponseTime"
            @focus="enterEditMode" />
          <app-text v-else class="ad-approval-response-time__content-time" type="p" size="h1">
            {{ responseTime }}
          </app-text>
          <app-text type="span" class="ad-approval-response-time__content-hour">
            hours
          </app-text>
        </template>
        <transition name="fade" mode="out-in">
          <app-text v-if="feedbackMessage.type" type="div" class="ad-approval-response-time__feedback" :class="feedbackMessage.class">
            {{ feedbackMessage.message }}
          </app-text>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonBox from '@/components/skeletons/SkeletonBox';
import AdApprovalMixin from '@/mixins/AdApprovalMixin';

export default {
  name: 'AdApprovalResponseTime',
  components: { SkeletonBox },
  mixins: [AdApprovalMixin],
  props: {
    value: {
      type: String,
      default: '24'
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      maxResponseTime: 48,
      responseTimeSaved: false,
      isInvalid: false,
      inEditingMode: false
    };
  },
  computed: {
    isAffiliate() {
      return this.$store.getters.userIsAffiliate;
    },
    isAdvertiser() {
      return this.$store.getters.userIsAdvertiser;
    },
    maxNumber() {
      return this.responseTime > this.maxResponseTime;
    },
    invalidEntry() {
      return parseInt(this.responseTime) <= 0;
    },
    responseTime: {
      get() { return this.value; },
      set(responseTime) {
        this.$emit('input', responseTime);
      }
    },
    feedback() {
      return {
        maxNumber: {
          name: 'error',
          type: 'maxnumber',
          class: 'error',
          message: `Response time cannot exceed ${this.maxResponseTime}h`
        },
        invalidEntry: {
          name: 'error',
          type: 'invalidEntry',
          class: 'error',
          message: 'Please provide a Response time'
        },
        success: {
          name: 'success',
          type: 'saved',
          class: 'saved',
          message: 'Response time saved'
        }
      };
    },
    feedbackMessage() {
      if (this.maxNumber) return this.feedback.maxNumber;
      if (this.invalidEntry) return this.feedback.invalidEntry;
      if (this.responseTimeSaved) return this.feedback.success;
      return {};
    }
  },
  methods: {
    async saveResponseTime(responseTime) {
      if (!responseTime || this.maxNumber || this.invalidEntry) {
        this.$refs.elInput.focus();
        return;
      }

      try {
        this.responseTimeSaved = true;
        this.inEditingMode = false;
        await this.$api.adApprovals.setResponseTime({
          campaignId: this.campaignId,
          params: { responseTime: responseTime }
        });
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        if (this.$api.hasErrors(error)) {
          error.response.data._meta.errors.forEach((message, index) => {
            // allow enough time for previous message to animate in
            setTimeout(() => this.$message({ message, type: 'error' }), index * 300);
          });
          return;
        }
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.responseTimeSaved = false;
      }
    },
    enterEditMode() {
      this.$refs.elInput.focus();
      this.inEditingMode = true;
    }
  }
};
</script>

<style lang="scss">
.ad-approval-response-time {
  margin: 0 0 $--clb-layout-1 0;
  padding: $--clb-space-4;
  border-radius: $--clb-border-radius;
  border: $--clb-border-complete-light;
  min-width: 18rem;

  @include xs-up {
    min-width: 19rem;
  }

  @include sm-up {
    margin: 0 $--clb-layout-1 $--clb-layout-1 0;
  }

  &__content {
    .el-icon-edit-outline:hover {
      cursor: pointer;
    }

    &-header__instructions {
      margin-bottom: $--clb-space-4 !important;
      max-width: 336px;
    }

    &__input {
      display: flex;
      align-items: center;
      margin-left: $--clb-space-4;

      .el-input {
        width: 57px;
        min-width: 57px;
        border-color: grey;
        border-radius: $--clb-space-1;
        margin-right: $--clb-space-4;
        padding: 0;

        input {
          font-size: $--clb-h2__font-size;
          text-align: center;
          font-weight: $--clb-font-weight__medium;
          padding: 0;
          transition: border 0.2s;
        }

        &.border-error .el-input__inner {
          border-color: $--clb-color-danger;
          color: $--clb-color-danger;
        }

        &.border-success .el-input__inner {
          border-color: $--clb-color-success;
          color: $--clb-color-success;
        }

        &.hide-border .el-input__inner {
          &:hover {
            border: 1px solid  $--clb-color-primary !important;
          }

          border: transparent;
          transition: border-color 0.2s;
        }
      }
    }

    &-hour {
      margin-right: $--clb-space-6;
    }

    &-time {
      width: 50px;
      font-size: $--clb-h2__font-size !important;
      font-weight: $--clb-font-weight__medium !important;
      border-color: $--clb-color-grey__white-ter;
      border-radius: $--clb-space-1;
      margin: 0 !important;
    }
  }

  &__feedback {
    width: 10rem;

    &.error {
      color: $--clb-color-danger;
    }

    &.saved {
      color: $--clb-color-success;
    }
  }
}
</style>
