<template>
  <div class="ad-approval-banned-words">
    <div class="ad-approval-banned-words__header">
      <app-heading level="h2" size="h4" weight="semi-bold">
        Banned Words
      </app-heading>
      <app-button v-if="!isAffiliate" state="text">
        <i class="el-icon-edit" :class="{'active': inEditingMode}" @click="toggleEditMode" />
      </app-button>
    </div>
    <app-text type="p">
      These are the words that are not allowed for this campaign.
    </app-text>
    <transition v-if="!isAffiliate" name="fade" mode="out-in">
      <div v-if="inEditingMode" class="ad-approval-banned-words__input">
        <el-input
          ref="elInput"
          v-model="newWord"
          class="el-input"
          type="text"
          placeholder=""
          @keyup.native.enter="handleAddBannedWord"
          @blur="handleBlur" />
        <app-button class="ad-approval-banned-words__add-btn" :disabled="(!newWord || bannedWords[trimmedNewWord])" @click="handleAddBannedWord">
          Add
        </app-button>
      </div>
    </transition>
    <transition-group class="ad-approval-banned-words__pills" tag="div" name="fade">
      <span
        v-for="word in Object.keys(bannedWords)"
        :key="word"
        class="ad-approval-banned-words__pills-word"><i v-if="!isAffiliate" :class="{'el-icon-close' : inEditingMode}" @click="handleRemoveBannedWord(word)" />
        {{ word }}
      </span>
    </transition-group>

    <transition-group name="fade" tag="div">
      <app-button
        v-if="!isAffiliate && inEditingMode"
        key="btn"
        ref="savebutton"
        :disabled="Object.keys(bannedWords).length == 0 || bannedWordSaved"
        class="ad-approval-banned-words__save-btn"
        @click="handleSaveBannedWords">
        Save
      </app-button>
      <app-text
        v-if="feedbackMessage && inEditingMode"
        key="feedback"
        type="div"
        :color="feedbackMessage.color">
        {{ feedbackMessage.message }}
      </app-text>
    </transition-group>
  </div>
</template>

<script>
import AdApprovalHelper from '@/helpers/AdApprovalHelper';
import AdApprovalMixin from '@/mixins/AdApprovalMixin';

export default {
  name: 'AdApprovalBannedWords',
  mixins: [AdApprovalMixin],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      newWord: '',
      inEditingMode: false,
      bannedWordSaved: false
    };
  },
  computed: {
    bannedWords: {
      get() {
        return this.value;
      },
      set(bannedWords) {
        this.$emit('input', bannedWords);
      }
    },
    feedback() {
      return {
        success: {
          name: 'success',
          type: 'saved',
          color: 'success',
          message: 'Banned words saved'
        },
        invalidEntry: {
          name: 'error',
          type: 'invalidEntry',
          color: 'danger',
          message: `${this.newWord} has been added already`
        }
      };
    },
    feedbackMessage() {
      if (this.bannedWordSaved) return this.feedback.success;
      if (this.bannedWords[this.trimmedNewWord]) return this.feedback.invalidEntry;
      return false;
    },
    trimmedNewWord() {
      return this.newWord.trim().toLowerCase();
    }

  },
  methods: {
    toggleEditMode() {
      this.inEditingMode = !this.inEditingMode;

      if (this.inEditingMode) {
        this.$nextTick(() => {
          this.focusOnInput();
        });
      } else {
        this.handleSaveBannedWords();
      }
    },
    handleBlur() {
      if (this.newWord) {
        this.$nextTick(() => {
          this.focusOnInput();
        });
      }
    },
    async handleSaveBannedWords() {
      try {
        this.bannedWordSaved = true;
        const options = {
          campaignId: this.campaignId,
          params: {
            words: AdApprovalHelper.convertToArray(this.bannedWords)
          }
        };
        await this.$api.adApprovals.setBannedWords({ storeAction: 'setAdBannedWords', ...options });
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        if (this.$api.hasErrors(error)) {
          error.response.data._meta.errors.forEach((message, index) => {
            // allow enough time for previous message to animate in
            setTimeout(() => this.$message({ message, type: 'error' }), index * 300);
          });
          return;
        }
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.bannedWordSaved = false;
        this.inEditingMode = false;
      }
    },
    handleAddBannedWord() {
      if (!this.newWord) return;
      this.$set(this.bannedWords, this.trimmedNewWord, true);
      this.newWord = '';
    },
    focusOnInput() {
      this.$nextTick(() => {
        this.$refs.elInput.focus();
      });
    },
    handleRemoveBannedWord(word) {
      this.$delete(this.bannedWords, word);
    }
  }
};

</script>

<style lang="scss">
  .ad-approval-banned-words {
    margin: 0 0 $--clb-space-4 0;
    background: $--clb-color-primary__white;
    padding: $--clb-space-4;
    border-radius: $--clb-border-radius;
    border: $--clb-border-complete-light;
    min-width: 18rem;
    flex-grow: 1;

    &__header {
      display: flex;

      .app-button {
        padding: 0 0 $--clb-space-3 $--clb-space-2;

        .el-icon-edit {
          transition: color 0.3s;
          margin: $--clb-space-1 0 0 $--clb-space-1;
          color: $--clb-body-font;

          &.active {
            color: $--clb-color-grey__grey-light;
          }
        }
      }
    }

    &__input {
      max-width: 432px;
      margin-top: $--clb-space-3;
      display: flex;

      .el-input__inner {
        margin-bottom: $--clb-space-4 !important;
        max-width: 338px;
        min-height: 40px !important;
        max-height: 40px !important;
        font-size: $--clb-font-size-base;
      }
    }

    &__pills {
      display: flex;
      flex-wrap: wrap;

      @include md-up {
        margin: 0 0 $--clb-space-4 0;
      }

      &-word {
        display: flex;
        align-items: center;
        color: $--clb-color-accent__light;
        text-transform: lowercase;
        background: $--clb-color-grey__grey-lighter;
        padding: $--clb-space-2;
        margin: $--clb-space-1 $--clb-space-1 $--clb-space-1 0;
        border-radius: $--clb-space-1;
        font-size: $--clb-font-size-base;
        font-weight: $--clb-font-weight__bold;
        min-height: $--clb-layout-3;
        word-break: break-word;

        .el-icon-close {
          padding-right: $--clb-space-2;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    &__save-btn {
      margin-bottom: $--clb-space-3;
    }
  }
</style>
