<template>
  <div class="ad-approval-row-content sm:tw-flex tw-items-center tw-block tw-w-full">
    <div class="ad-approval-row-content__info tw-flex tw-items-center">
      <div class="ad-approval-row-content__date-wrapper tw-pr-layout-1" :style="{ width: colWidth, minWidth: colWidth }">
        <slot name="date" :date="formatTime(item.requested_at)">
          <app-text size="sm">
            {{ formatTime(item.requested_at) | capitalize }}
          </app-text>
        </slot>
      </div>

      <div v-if="!isAffiliate" class="ad-approval-row-content__action-wrapper tw-pr-layout-1" :style="{ width: colWidth, minWidth: colWidth }">
        <slot name="action-name" :actionName="getActionName(item)">
          <app-text size="sm">
            {{ getActionName(item) }}
          </app-text>
        </slot>
      </div>

      <slot v-if="xsDown" name="mobile-actions" />
    </div>

    <div class="ad-approval-row-content__content-wrapper tw-pr-layout-1 tw-w-full">
      <template v-if="!isVisualContent(item)">
        <slot name="text-content">
          <div class="ad-approval-row-content__content--text tw-whitespace-pre-line" :style="{ wordBreak: 'break-word' }">
            <app-text weight="lighter" color="grey-dark" size="xs">
              {{ item.content.type | capitalize }}
            </app-text>
            <app-text type="div" weight="semi-bold">
              {{ item.content.content }}
            </app-text>
          </div>
        </slot>
      </template>

      <slot v-else-if="item.content.metadata && item.content.metadata.type" name="visual-content">
        <div class="ad-approval-row-content__content--visual tw-flex tw-items-center">
          <app-preview-card
            v-if="item.content.metadata.type.match('image/*')"
            width="40"
            height="40"
            :background-image="resizeImage(item.content.content)"
            @click.native="$emit('handle-image-click', item.content.content)" />
          <video
            v-else-if="item.content.metadata.type.match('video/*')"
            preload="metadata"
            :src="`${item.content.content}#t=0.001`"
            :type="item.content.metadata.type"
            @click="$emit('handle-image-click', item.content.content, true)" />

          <div class="tw-ml-space-3">
            <app-text type="div" weight="medium" color="grey-darker" size="xs">
              {{ item.content.name }}
            </app-text>
            <app-text
              type="div"
              weight="normal"
              color="primary"
              size="xs"
              class="hover:tw-text-primary-dark tw-transition tw-cursor-pointer"
              @click="$emit('handle-image-click', item.content.content, !!item.content.metadata.type.match('video/*'))">
              {{ !!item.content.metadata.type.match('video/*') ? 'Watch Video' : 'Open Image' }}
            </app-text>
          </div>
        </div>
      </slot>

      <slot name="edit-content" />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import AppPreviewCard from '@/components/AppPreviewCard';
import AdApprovalMixin from '@/mixins/AdApprovalMixin';
import imageService from '@/services/image-service';

export default {
  name: 'AdApprovalRowContent',
  components: { AppPreviewCard },
  mixins: [AdApprovalMixin],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    resizeImage(image, options = {}) {
      return imageService.resizeImage(image, {
        w: 40,
        h: 40,
        auto: 'enhance,format',
        fit: 'crop',
        crop: 'top',
        bg: 'efefef',
        fm: 'jpeg',
        q: 85,
        ...options
      });
    },
    formatTime(time) {
      const contentDate = dayjs(time);
      if (dayjs(contentDate).isValid()) return dayjs(contentDate).fromNow();
      return 'unknown';
    },
    isVisualContent(row) {
      return row.content.type === 'visual';
    },
    getActionName(row) {
      if (this.isAdvertiser && row.requested_by) return row.requested_by.name || row.requested_by.id;
      return row.action_by.name || row.action_by.id;
    }
  }
};
</script>

<style lang="scss">
.ad-approval-row-content {
  video {
    width: 40px;
    height: 40px;
    min-width: 40px;
    background-color: $--clb-color-grey__grey-light;
    border-radius: $--clb-border-radius;
    overflow: hidden;
    transition: transform $--clb-hover-transition, box-shadow $--clb-hover-transition, border $--clb-hover-transition;
    border: 1px solid $--clb-border-color-base;
    transform: scale(1.01);
    object-fit: cover;

    &:hover {
      border: 1px solid $--clb-color-primary;
      box-shadow: 0 2px 8px 0 $--clb-shadow-color;
      cursor: pointer;
      transform: scale(1.08);
      transform: translateY(-4px) !important;
    }
  }
}
</style>
