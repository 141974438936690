<template>
  <div
    class="ad-approval-table"
    :class="{
      ['ad-approval-table--loading']: loading,
      ['ad-approval-table--empty']: !hasData
    }">
    <transition name="fade" mode="out-in">
      <!-- loading -->
      <div v-if="loading" class="tw-flex tw-items-center">
        <div class="tw-pr-layout-2 tw-w-1/5">
          <skeleton-box width="100%" height="16px" />
        </div>
        <div class="tw-w-4/5">
          <skeleton-box width="100px" height="12px" class="tw-mb-space-2" />
          <skeleton-box width="75%" height="16px" />
        </div>
      </div>

      <!-- empty -->
      <ad-approval-empty-state v-else-if="!hasData || !campaignId" />

      <!-- data -->
      <div v-else>
        <div class="ad-approval-table-header" :class="isAffiliate ? 'tw-ml-layout-1' : 'tw-ml-layout-2'">
          <span
            v-for="header in tableHeaders"
            :key="`header-${header.label}`"
            :style="{ width: colWidth, minWidth: colWidth }"
            @click="sortContent(header)">
            {{ header.label }} <i class="el-icon-d-caret" />
          </span>
        </div>
        <div class="ad-approval-table-content">
          <ad-approval-row v-for="item in contentData" :key="item.request_id" :item="item" />
        </div>
        <el-pagination
          small
          class="ad-approval-table__pagination"
          :page-size="pagination.per_page"
          :current-page="parseInt(pagination.page)"
          layout="prev, pager, next"
          :total="pagination.result_count"
          @current-change="pageChange" />
      </div>
    </transition>
  </div>
</template>

<script>
import SkeletonBox from '@/components/skeletons/SkeletonBox';
import AdApprovalRow from '@/components/adApprovals/adApprovalRow/AdApprovalRow';
import AdApprovalEmptyState from '@/components/adApprovals/AdApprovalEmptyState';
import AdApprovalMixin from '@/mixins/AdApprovalMixin';

export default {
  name: 'AdApprovalTable',
  components: { SkeletonBox, AdApprovalRow, AdApprovalEmptyState },
  mixins: [AdApprovalMixin],
  props: {
    data: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    headerData() {
      return {
        advertiser: [
          { id: 'requested_at', label: this.activeTab === 'approved' ? 'Submitted' : 'Due' },
          { id: 'requested_by', label: 'Affiliate' },
          { id: 'content_content', label: 'Content' }
        ],
        employee: [
          { id: 'requested_at', label: this.activeTab === 'approved' ? 'Confirmed' : 'Due' },
          { id: 'requested_by', label: 'Affiliate' },
          { id: 'content_content', label: 'Content' }
        ],
        affiliate: [
          { id: 'requested_at', label: 'Submitted' },
          { id: 'content_content', label: 'Content' }
        ]
      };
    },
    tableHeaders() {
      return this.headerData[this.orgType];
    },
    hasData() {
      return this.campaignId && this.contentData.length;
    },
    contentData() {
      return this.data.data;
    },
    pagination() {
      return this.data.pagination;
    },
    sortBy() {
      return this.data.sortBy;
    }
  },
  methods: {
    sortContent(header) {
      this.$emit('get-contents', { showLoader: false, pagination: this.pagination, sortBy: header.id });
    },
    pageChange(currentPage) {
      this.$emit('get-contents', { showLoader: true, pagination: { page: currentPage }, sortBy: this.sortBy });
    }
  }
};
</script>

<style lang="scss">
  .ad-approval-table {
    margin-top: $--clb-space-5;

    &-header {
      display: flex;

      > span {
        font-size: $--clb-font-size-sm;
        padding-right: $--clb-layout-1;
        cursor: pointer;
      }

      @include xs-down {
        :last-child {
          display: none;
        }
      }
    }

    &-content {
      margin-top: $--clb-space-2;
    }

    svg.skeleton-box {
      margin: 0;
    }

    &__pagination {
      margin-top: $--clb-space-2;
      padding: 0;

      .el-pager {
        .number {
          color: $--clb-color-grey__grey;
          box-shadow: 0 2px 0 rgba(white, 0);
          transition: $--clb-hover-transition;

          &:hover {
            color: $--clb-color-grey__grey-dark;
          }

          &.active {
            color: $--clb-color-primary;
          }
        }
      }
    }
  }
</style>
