var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ad-approval-history",class:( _obj = {}, _obj['ad-approval-history--affiliate'] = _vm.isAffiliate, _obj['ad-approval-history--loading'] = _vm.loading, _obj['ad-approval-history--empty'] = _vm.history.length === 0, _obj )},[(_vm.loading)?_c('div',{staticClass:"tw-flex"},[_c('div',{staticClass:"tw-pr-layout-1",style:({ width: _vm.colWidth, minWidth: _vm.colWidth })},[_c('skeleton-box',{attrs:{"width":"100%","height":"16px"}})],1),(!_vm.isAffiliate && _vm.smUp)?_c('div',{staticClass:"tw-pr-layout-1",style:({ width: _vm.colWidth, minWidth: _vm.colWidth })},[_c('skeleton-box',{attrs:{"width":"100%","height":"16px"}})],1):_vm._e(),_c('div',{staticClass:"tw-w-full"},[_c('skeleton-box',{staticClass:"tw-mb-space-2",attrs:{"width":"90%","height":"16px"}}),_c('skeleton-box',{attrs:{"width":"80%","height":"16px"}})],1)]):(_vm.history.length === 0)?_c('div',{staticClass:"tw-text-center"},[_c('app-text',{attrs:{"weight":"medium","color":"grey-dark"}},[_c('i',{staticClass:"el-icon-warning-outline"}),_vm._v(" No history for this request ")])],1):_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('app-text',{staticClass:"ad-approval-history__title",attrs:{"type":"div","size":"xs","weight":"medium","color":"default"}},[_vm._v(" Revision History ")]),_c('div',{staticClass:"ad-approval-history__rows"},_vm._l((_vm.history),function(row){return _c('div',{key:("ad-approval-history-" + (row.request_id) + "-" + (row.version)),staticClass:"ad-approval-history__inner"},[_c('ad-approval-row-content',{key:("ad-approval-history-content-" + (row.request_id) + "-" + (row.version)),attrs:{"item":row},on:{"handle-image-click":_vm.handleImageClick},scopedSlots:_vm._u([{key:"date",fn:function(ref){
  var date = ref.date;
return [(!_vm.hasNotes(row))?_c('app-text',{attrs:{"size":"xs","color":"grey-dark"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(date))+" ")]):_c('span')]}},{key:"action-name",fn:function(ref){
  var actionName = ref.actionName;
return [(!_vm.hasNotes(row))?_c('app-text',{attrs:{"size":"xs","color":"grey-dark"}},[_vm._v(" "+_vm._s(actionName)+" ")]):_c('span')]}},{key:"text-content",fn:function(){return [_c('app-text',{staticClass:"tw-whitespace-pre-line",style:({ wordBreak: 'break-word' }),attrs:{"type":"div","size":_vm.smUp ? 'md' : 'sm',"weight":"medium","color":"grey-darker"}},[_vm._v(" "+_vm._s(row.content.content)+" ")])]},proxy:true}],null,true)}),_vm._l((row.notes),function(note){return _c('div',{key:("ad-approval-history-note-" + (note.request_id) + "-" + (note.version)),staticClass:"ad-approval-history__inner-row tw-flex tw-items-stretch"},[_c('ad-approval-row-content',{key:("ad-approval-history-note-content-" + (note.request_id) + "-" + (note.version)),staticClass:"tw-mb-space-1 tw-items-stretch",attrs:{"item":note},on:{"handle-image-click":_vm.handleImageClick},scopedSlots:_vm._u([{key:"date",fn:function(ref){
  var date = ref.date;
return [_c('app-text',{attrs:{"size":"xs","color":"grey-dark"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(date))+" ")])]}},{key:"action-name",fn:function(ref){
  var actionName = ref.actionName;
return [_c('app-text',{attrs:{"size":"xs","color":"grey-dark"}},[_vm._v(" "+_vm._s(actionName)+" ")])]}},{key:"text-content",fn:function(){return [_c('app-text',{staticClass:"ad-approval-history__inner-note tw-whitespace-pre-line",style:({ wordBreak: 'break-word' }),attrs:{"type":"div","size":_vm.smUp ? 'sm' : 'xs',"color":"grey-dark"}},[_vm._v(" "+_vm._s(note.note)+" ")])]},proxy:true},{key:"visual-content",fn:function(){return [_c('app-text',{staticClass:"ad-approval-history__inner-note tw-whitespace-pre-line",style:({ wordBreak: 'break-word' }),attrs:{"type":"div","size":_vm.smUp ? 'sm' : 'xs',"color":"grey-dark"}},[_vm._v(" "+_vm._s(note.note)+" ")])]},proxy:true}],null,true)})],1)})],2)}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }