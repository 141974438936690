<template>
  <div class="ad-approval-empty tw-text-center">
    <ad-approvals-empty-state-illustration />

    <app-text type="p" class="tw-block tw-mx-auto tw-text-center">
      Nothing to see here
      <template v-if="!campaignId">
        <br>
        <span>
          until you
          <span class="tw-text-primary hover:tw-text-primary-dark tw-font-semi-bold tw-inline-block tw-cursor-pointer" @click="openSidebar">
            select a campaign
          </span>
        </span>
      </template>
    </app-text>
  </div>
</template>

<script>
import AdApprovalsEmptyStateIllustration from '@/assets/svg/ad-approvals-empty-state-illustration.svg';
import AdApprovalMixin from '@/mixins/AdApprovalMixin';
import Breakpoints from '@/mixins/Breakpoints';

export default {
  name: 'AdApprovalEmptyState',
  components: { AdApprovalsEmptyStateIllustration },
  mixins: [AdApprovalMixin, Breakpoints([992])],
  data() {
    return {};
  },
  computed: {
    isMobileWidth() {
      return this.windowBreakpoint < 992;
    }
  },
  methods: {
    async openSidebar() {
      if (this.isMobileWidth) await this.$store.dispatch('setLeftSidebarVisibility');
      document.querySelector('.dropdown-filter .el-input__inner').focus(); // Can't use refs with element ui inner components
    }
  }
};
</script>
