<template>
  <div v-scrollable:xy class="app-lightbox" :class="{['app-lightbox--navigate']: navigate}">
    <el-dialog ref="dialog" :visible="isVisible" :width="aspectRatioWidth" @close="$emit('close')">
      <template v-if="navigate">
        <div class="app-lightbox__left" @click="navigateToImage(-1)">
          <button class="app-lightbox--arrow">
            <i class="el-icon-arrow-left" />
          </button>
        </div>
        <div class="app-lightbox__right" @click="navigateToImage(1)">
          <button class="app-lightbox--arrow">
            <i class="el-icon-arrow-right" />
          </button>
        </div>
      </template>
      <h2 v-if="title" class="app-lightbox__title">
        {{ title }}
      </h2>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-if="hasErrorLoading && errorMessage" class="app-lightbox--error" v-html="errorMessage" />
      <div v-else class="app-lightbox--image-container" :class="{ 'app-lightbox--image-container--error': hasErrorLoading }">
        <video
          v-if="isVideo && !hasErrorLoading"
          :key="computedImageUrl"
          ref="image"
          :src="`${computedImageUrl}#t=0.001`"
          controls="controls"
          preload="metadata"
          class="el-dialog__body-img"
          @loadeddata="() => handleLoad(imageUrl, true)"
          @error="handleImageError" />
        <img
          v-else
          v-show="loadedImages[imageUrl]"
          :key="computedImageUrl"
          ref="image"
          :src="computedImageUrl"
          alt="campaign asset"
          class="el-dialog__body-img"
          @load="() => handleLoad(imageUrl, true)"
          @error="handleImageError">
        <div v-loading="!isVideo && !loadedImages[imageUrl]" class="app-lightbox--loading">
          &nbsp;
        </div>
      </div>
      <a
        v-if="loadedImages[imageUrl] || hasErrorLoading"
        href="javascript:;"
        class="app-lightbox--link"
        @click="openNewTab">Open in New Tab</a>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog } from 'element-ui';
import ErrorImageGeneric from '@/assets/images/error-image-generic.png';

export default {
  name: 'AppLightbox',
  components: { 'el-dialog': Dialog },
  props: {
    navigate: {
      type: Boolean,
      default: true
    },
    imageUrl: {
      type: String,
      required: true
    },
    isVideo: {
      type: Boolean,
      default: false
    },
    isVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  data() {
    return {
      loadedImages: {},
      hasErrorLoading: false,
      aspectRatioWidth: null
    };
  },
  computed: {
    computedImageUrl() {
      return this.hasErrorLoading ? ErrorImageGeneric : this.imageUrl;
    },
    errorMessage() {
      if (this.hasErrorLoading) {
        // IE video
        const ua = window.navigator.userAgent;
        const isIE = ua.includes('MSIE ') || ua.includes('Trident/');
        if (isIE && this.isVideo) {
          return `
            <p>Looks like you're trying to access an unsupported feature of Internet Explorer.</p>
            <p>If you wish to watch this video please choose a different browser.</p>
          `;
        }
      }

      return '';
    }
  },
  watch: {
    imageUrl: {
      immediate: true,
      handler() {
        this.hasErrorLoading = false;
        this.handleLoad(this.imageUrl, false);
      }
    }
  },
  mounted() {
    window.addEventListener('keyup', this.setKeyBindings);
  },
  destroyed() {
    window.removeEventListener('keyup', this.setKeyBindings);
  },
  methods: {
    navigateToImage(direction) {
      if (this.$listeners.navigation) {
        this.$emit('navigation', direction);
      }
    },
    setKeyBindings(event) {
      if (event.keyCode === 37) this.navigateToImage(-1);
      if (event.keyCode === 39) this.navigateToImage(1);
    },
    openNewTab() {
      window.open(this.imageUrl, '_blank');
    },
    handleLoad(url, state) {
      this.$set(this.loadedImages, url, state);
      if (!this.navigate) this.handleParentAspectRatio();
    },
    handleImageError() {
      this.hasErrorLoading = true;
    },
    async handleParentAspectRatio() {
      await this.$nextTick();

      if (!this.$refs.image) {
        this.aspectRatioWidth = null;
        return;
      }

      const el = this.$refs.image;
      const width = el.videoWidth || el.naturalWidth;
      const height = el.videoHeight || el.naturalHeight;
      const ratio = width / height;
      const containerHeight = this.$refs.dialog.$refs.dialog.clientHeight;
      const dialogWidth = (containerHeight * ratio) - 64;
      if (width && height && ratio && dialogWidth) this.aspectRatioWidth = `${dialogWidth.toFixed()}px`;
    }
  }
};
</script>

<style lang="scss">
.app-lightbox {
  transition: all 3s ease;

  .el-dialog {
    height: calc(100vh - 220px);
    height: calc(100 * var(--vh, 1vh) - 220px);
    background: transparent;
    box-shadow: none;
    margin: auto;
    margin-top: 130px !important;
    width: $--clb-app-content-width;
    max-width: calc(100% - #{$--clb-layout-3 * 2});

    &__header {
      padding: 0;

      button {
        position: fixed;
        right: $--clb-mobile-padding;
        background: #7f7f7f;
        border-radius: 4px;
        border: 1px solid $--clb-border-color-base;
        font-size: $--clb-font-size-lg;
        padding: 0 $--clb-space-2 - 2;
        height: 36px;
        width: 36px;
        top: 12px;
        transition: 0.2s ease-in;

        i {
          color: $--clb-border-color-base !important;
          transition: 0.2s ease-in;
        }

        &:hover {
          border-color: $--clb-color-primary;
          background: $--clb-color-primary__white;
          transform: scale(1.1);

          i {
            color: $--clb-color-primary !important;
          }
        }
      }
    }

    .app-lightbox__title {
      width: 100%;
      width: calc(100% + 196px);
      margin: 0 0 $--clb-layout-1 0;
      padding: 0 $--clb-layout-2;
      font-size: $--clb-font-size-lg;
      text-align: center;
      color: $--clb-color-primary__white;
      text-shadow: 0 0 2px rgb(33, 33, 33);
      position: relative;
      word-break: break-word;
    }

    &__body {
      user-select: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0;
      border-radius: $--clb-border-radius;

      &-img {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
      }

      .app-lightbox--image-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        overflow: hidden;
        max-width: 100%;
        max-height: 100%;

        &--error {
          background: #e0e0e0;
          width: 100%;
          height: 100%;

          > img,
          > video {
            margin: auto;
          }
        }
      }

      .app-lightbox--loading {
        height: 100%;
        min-height: 60px;
        width: 60px;

        .el-loading-mask {
          background: none;
        }

        circle {
          stroke: $--border-color-base;
        }
      }
    }
  }

  &--navigate {
    .el-dialog {
      padding: 0 88px;
      width: 100%;

      @include xs-only {
        padding: $--clb-layout-4;
      }
    }
  }

  .app-lightbox__left {
    left: 0;
    justify-content: flex-start;

    .app-lightbox--arrow {
      margin-left: $--clb-space-6;

      @include xs-only {
        margin-left: $--clb-space-2;
      }
    }
  }

  .app-lightbox__right {
    right: 0;
    justify-content: flex-end;

    .app-lightbox--arrow {
      margin-right: $--clb-space-6;

      @include xs-only {
        margin-right: $--clb-space-2;
      }
    }
  }

  .app-lightbox__left,
  .app-lightbox__right {
    display: flex;
    align-items: center;
    position: absolute;
    width: 50%;
    height: 100%;
    cursor: pointer;

    .app-lightbox--arrow {
      width: 40px;
      cursor: pointer;
      border-radius: 100%;
      border: 1px solid $--border-color-base;
      color: $--border-color-base;
      font-size: $--clb-font-size-xl;
      line-height: $--clb-space-5;
      padding: $--clb-space-2;
      background: transparent;
      box-shadow: 0 0 6px 0 $--clb-shadow-color;
      transition: $--clb-hover-transition;
      outline: transparent;
      z-index: 10;

      @include xs-only {
        width: $--clb-layout-3;
        padding: $--clb-space-1;
      }
    }

    &:hover .app-lightbox--arrow,
    .app-lightbox--arrow:hover {
      transform: scale(1.1);
      text-decoration: none;
      color: $--jb-dark-primary-color;
      border-color: $--jb-dark-primary-color;
      background: $--clb-color-primary__white;
    }

    &:hover:active .app-lightbox--arrow,
    .app-lightbox--arrow:hover:active {
      transform: scale(1);
      background: $--color-light;
    }
  }

  .app-lightbox--error {
    text-align: center;
    color: $--clb-color-primary__white !important;
    font-weight: 700;
    text-shadow: 0 0 2px rgb(33, 33, 33);
    z-index: 1;

    p {
      font-size: $--clb-font-size-xl;
      line-height: $--clb-p__line-height;
      margin-bottom: $--clb-space-2;
    }
  }

  .app-lightbox--link {
    margin-top: $--clb-layout-1;
    text-align: center;
    color: $--clb-color-primary__white !important;
    font-weight: 700;
    cursor: pointer;
    text-shadow: 0 0 2px rgb(33, 33, 33);
    z-index: 1;
  }

  @media (min-width: $--xs) {
    .el-dialog {
      &__header {
        button {
          right: $--clb-space-6;
        }
      }
    }
  }

  @media (min-width: $--sm) {
    .el-dialog {
      margin-top: 16.5vh !important;
      height: 66vh;
      height: calc(66 * var(--vh, 1vh));
    }
  }
}
</style>
